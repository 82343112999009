import { Ref } from "vue";
import { AxiosResponse } from "axios";
import useBaseCostAvailabilityByMfrRegion from "@/composables/costing-parameters/useBaseCostAvailabilityByMfrRegion";
import useBaseCostMethods from "@/composables/costing-parameters/useBaseCostMethods";
import useBaseCostCostingByMfrRegion from "@/composables/costing-parameters/useBaseCostCostingByMfrRegion";
import useBaseCostLeadTimeByMfrRegion from "@/composables/costing-parameters/useBaseCostLeadTimeByMfrRegion";
import useBaseCostMoqByMfrRegion from "@/composables/costing-parameters/useBaseCostMoqByMfrRegion";
import useBaseCostCTQDimLeadTimeByMfrRegion from "@/composables/costing-parameters/useBaseCostCTQDimLeadTimeByMfrRegion";
import useBaseCostMinCostByMfrRegion from "@/composables/costing-parameters/useBaseCostMinCostByMfrRegion";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";
import AvailabilityInterface from "@/types/admin-parameters/costing-parameters/AvailabilityInterface";

export default (
  data: Ref<AvailabilityInterface[]>,
  keyField: string,
  showColumns: {
    showLeadTimeColumn: boolean;
    showCTQLeadTimeColumn: boolean;
    showMOQColumn: boolean;
    showMinCostColumn: boolean;
  },
  addRequest: (payload: BaseCostInterface[]) => Promise<AxiosResponse>,
  changeRequest: (payload: BaseCostInterface) => Promise<AxiosResponse>,
  onClickCostCell: () => void,
  onClickLeadTimeCell: () => void,
  onClickMinCostCell?: () => void,
  onClickCTQDimLeadTimeCell?: () => void,
  onClickMOQCell?: () => void
) => {
  const {
    isValidMfrRegionOption,
    getDataIndexes,
    getMfrRegionOptions,
    onUpdateTable,
  } = useBaseCostMethods(data, showColumns);

  const {
    baseCostValidationError,
    onUpdateAvailabilityValue,
    onUpdateAvailability,
    onClickOutsideBaseCostPopover,
  } = useBaseCostAvailabilityByMfrRegion(
    data,
    keyField,
    showColumns,
    addRequest,
    changeRequest,
    onClickCostCell,
    onClickMOQCell
  );

  const { onUpdateCost, onUpdateCostValue } = useBaseCostCostingByMfrRegion(
    data,
    keyField,
    showColumns,
    addRequest,
    changeRequest,
    onClickLeadTimeCell,
    onClickMinCostCell,
    onClickCTQDimLeadTimeCell,
    onClickMOQCell
  );

  const { onUpdateLeadTimeValue, onUpdateLeadTime } =
    useBaseCostLeadTimeByMfrRegion(
      data,
      keyField,
      showColumns,
      addRequest,
      changeRequest,
      onClickCostCell,
      onClickCTQDimLeadTimeCell,
      onClickMOQCell
    );
  const { onUpdateCTQDimLeadTime, onUpdateCTQDimLeadTimeValue } =
    useBaseCostCTQDimLeadTimeByMfrRegion(
      data,
      keyField,
      showColumns,
      addRequest,
      changeRequest,
      onClickCostCell,
      onClickLeadTimeCell
    );

  const { onUpdateMoq, onUpdateMoqValue } = useBaseCostMoqByMfrRegion(
    data,
    keyField,
    showColumns,
    addRequest,
    changeRequest,
    onClickCostCell,
    onClickMOQCell
  );

  const { onUpdateMinCost, onUpdateMinCostValue } =
    useBaseCostMinCostByMfrRegion(
      data,
      keyField,
      showColumns,
      addRequest,
      changeRequest,
      onClickCostCell,
      onClickLeadTimeCell
    );

  return {
    baseCostValidationError,
    isValidMfrRegionOption,
    getDataIndexes,
    getMfrRegionOptions,
    onUpdateAvailability,
    onUpdateAvailabilityValue,
    onUpdateCost,
    onUpdateCostValue,
    onUpdateLeadTime,
    onUpdateLeadTimeValue,
    onUpdateCTQDimLeadTime,
    onUpdateCTQDimLeadTimeValue,
    onUpdateMoq,
    onUpdateMoqValue,
    onUpdateMinCost,
    onUpdateMinCostValue,
    onUpdateTable,
    onClickOutsideBaseCostPopover,
  };
};
