
import { computed, defineComponent, h, nextTick, ref, watch } from "vue";
import { NInput, useLoadingBar } from "naive-ui";
import popupMessageDefaultConfig from "@/constants/popupMessageDefaultConfig";
import { onFocusElement } from "@/composables/onFocusElement";
import { decimalRegExp, integerRegExp } from "@/regex/numberRegex";

export default defineComponent({
  props: {
    value: [String],
    prefix: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
    validateAndGetPayload: {
      type: Function,
      required: true,
    },
    updateFunction: {
      type: [Function],
    },
    updateMessage: {
      type: String,
    },
    onUpdateValue: {
      type: [Function],
    },
    isUpdateAfterSendingRequest: {
      type: Boolean,
      default: true,
    },
    isNumber: {
      type: Boolean,
      default: false,
    },
    isInteger: {
      type: Boolean,
      default: false,
    },
    isString: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
    },
    withSavingLogic: {
      type: Boolean,
      default: true,
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
    },
    triggerOnBlur: {
      type: Boolean,
    },
    withError: {
      type: Boolean,
    },
    notEmpty: {
      type: Boolean,
      default: true,
    },
    showPlaceholder: {
      type: Boolean,
    },
    onThenActionsTrigger: {
      type: Boolean,
    },
  },
  emits: ["editMode", "keydownTab"],

  setup(props, { emit }) {
    const isEdit = ref(false);
    const isThenTriggered = ref(true);
    const inputRef = ref<HTMLInputElement>();
    const inputValue = ref(props.value);
    const trigger = ref(props.triggerOnBlur);
    const loading = ref(false);
    const loadingBar = useLoadingBar();

    watch(
      () => inputValue.value,
      () => {
        if (props.triggerOnBlur && !trigger.value) {
          trigger.value = true;
        }
      }
    );
    watch(
      () => isEdit.value,
      () => {
        emit("editMode", isEdit.value);
      }
    );
    watch(
      () => props.onThenActionsTrigger,
      () => {
        if (
          props.onThenActionsTrigger &&
          !isThenTriggered.value &&
          isHandleChangeTriggered.value
        ) {
          thenActions();
        }
      }
    );

    function handleOnClick() {
      isEdit.value = true;
      isThenTriggered.value = false;
      nextTick(() => {
        if (props.withSavingLogic) {
          inputValue.value = "";
        } else {
          inputValue.value = props.value;
        }
        onFocusElement(inputRef);
      });
    }

    const isHandleChangeTriggered = ref(false);
    async function handleChange() {
      if (inputValue.value === props.value) {
        isEdit.value = false;
        return;
      }
      const payload = await props.validateAndGetPayload(inputValue.value);
      if (payload === undefined) {
        return;
      }
      if (props.notEmpty && payload === "") {
        isEdit.value = false;
        return;
      }
      isHandleChangeTriggered.value = true;
      if (!props.updateFunction || !props.updateFunction(payload)) {
        props.onUpdateValue?.(payload);
        isEdit.value = false;
        return;
      }
      loading.value = true;
      loadingBar.start();
      props
        .updateFunction(payload)
        .then(() => {
          if (props.updateMessage) {
            window.$message.success(
              props.updateMessage,
              popupMessageDefaultConfig
            );
          }
          if (props.isUpdateAfterSendingRequest) {
            props.onUpdateValue?.(payload);
          }
          isEdit.value = false;
        })
        .finally(() => {
          loading.value = false;
          nextTick(() => {
            loadingBar.finish();
            isEdit.value = false;
          });
        });
    }
    const thenActions = () => {
      isThenTriggered.value = true;
      if (props.updateMessage) {
        window.$message.success(props.updateMessage, popupMessageDefaultConfig);
      }
      isEdit.value = false;
    };
    function handleBlur(e?: FocusEvent) {
      const target = e ? (e.relatedTarget as HTMLElement) : undefined;
      if (!target || !target.classList.contains("save-changes-button")) {
        props.onUpdateValue?.(props.value);
        isEdit.value = false;
      }
    }
    function handleKeydown(e: KeyboardEvent) {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        handleChange();
      } else if (e.code === "Escape") {
        handleBlur();
      } else if (e.code === "Tab") {
        isEdit.value = false;
        emit("keydownTab", e.shiftKey);
      }
    }

    const getInputClasses = computed(() => {
      return [
        "editable-table-cell",
        loading.value || props.disabled ? "disabled" : "",
        props.showEditButton ? "" : "full-height",
        !props.showEditButton && isEdit.value ? "bordered" : "",
        !props.value && props.showPlaceholder ? "placeholder-view" : "",
      ];
    });
    const showButton = () => {
      return props.withSavingLogic
        ? props.showSaveButton
          ? h(
              "button",
              {
                class: "save-changes-button",
                onClick: () => handleChange(),
              },
              ["Save"]
            )
          : undefined
        : undefined;
    };

    return () =>
      h(
        "div",
        {
          class: getInputClasses.value,
          onClick: () =>
            !props.showEditButton && !props.disabled
              ? handleOnClick()
              : undefined,
        },
        [
          isEdit.value
            ? h(NInput, {
                class: ["edit-input", props.withError ? "error" : ""],
                ref: inputRef,
                value: inputValue.value,
                autosize: true,
                style: "min-width: 100%",
                placeholder: props.value || "Enter",
                onKeydown: (e: KeyboardEvent) => {
                  handleKeydown(e);
                },
                onUpdateValue: (v: string) => {
                  if (props.isString) {
                    inputValue.value = v.trim();
                  } else if (!(props.max && Number(v) > props.max)) {
                    inputValue.value = props.isNumber
                      ? props.isInteger
                        ? v.replace(integerRegExp, "")
                        : v.replace(/[^.\d]+/g, "").replace(decimalRegExp, "$1")
                      : v;
                  }
                },
                onBlur: (e: FocusEvent) => {
                  if (trigger.value && isEdit.value) {
                    handleChange();
                    trigger.value = false;
                    onFocusElement(inputRef);
                  } else if (isEdit.value) {
                    handleBlur(e);
                  }
                },
              })
            : !props.value && props.showPlaceholder
            ? "Enter"
            : `${props.prefix}${props.value}${props.suffix}`,
          isEdit.value
            ? showButton()
            : props.showEditButton
            ? h("button", {
                class: "edit-cell-button",
                onClick: () => handleOnClick(),
              })
            : undefined,
        ]
      );
  },
});
