import { NButton, NDropdown, NIcon, NInput, SelectOption } from "naive-ui";
import { h, nextTick, Ref, ref, VNodeChild } from "vue";
import { AxiosResponse } from "axios";
import ThreeDotMenu from "@/components/icons/ThreeDotMenu.vue";
import loadingComposable from "@/composables/loadingComposable";
import { onFocusElement } from "@/composables/onFocusElement";
import { OptionsInterface } from "@/types/costing-parameters/OptionsInterface";

export default (
  inputRef: Ref<HTMLInputElement | undefined>,
  inputId: Ref,
  newChangeableInputValue: Ref,
  isEdit: Ref,
  rowOptions: Ref,
  changeOptionFunction: (optionData: {
    oldValue: OptionsInterface;
    newValue: { id: string; name: string };
  }) => Promise<AxiosResponse>,
  deleteOptionFunction: (id: string) => Promise<AxiosResponse>,
  onUpdateEmit: (newData: { id: string; name: string }) => void,
  onDeleteEmit: (optionValue: string) => void
) => {
  const changeInputRef = ref<HTMLInputElement>();
  const { startLoader, finishLoader } = loadingComposable();

  const onChangeOption = (option: OptionsInterface) => {
    changeOptionFunction({
      oldValue: option,
      newValue: {
        id: `${option.value}`,
        name: newChangeableInputValue.value,
      },
    })
      ?.then((res) => {
        onUpdateEmit(res?.data.data);
        inputId.value = "";
      })
      .finally(() => {
        finishLoader();
      });
  };

  const renderLabel = (option: SelectOption): VNodeChild => {
    return option.value === inputId.value
      ? [
          h(NInput, {
            class: ["editable-option", "input-borderless"],
            ref: changeInputRef,
            defaultValue: `${option.label}`,
            modelValue: newChangeableInputValue.value,
            onKeydown: (e: KeyboardEvent) => {
              if (e.code === "Space") {
                e.stopPropagation();
              } else if (e.code === "Enter" || e.code === "NumpadEnter") {
                e.stopPropagation();
                onChangeOption(option as OptionsInterface);
              }
            },
            onInput: (value: string) => {
              newChangeableInputValue.value = value;
            },
            onBlur: (e?: FocusEvent) => {
              const target = e
                ? (e.relatedTarget as HTMLElement) || (e.target as HTMLElement)
                : undefined;
              if (
                (e?.relatedTarget as HTMLElement)?.classList.contains(
                  "n-input__input-el"
                )
              ) {
                inputId.value = "";
                newChangeableInputValue.value = "";
                nextTick(() => {
                  onFocusElement(inputRef);
                });
              } else if (
                !target?.classList.contains("save-option-changes-button") &&
                (e?.target as HTMLElement)?.classList.contains(
                  "n-input__input-el"
                )
              ) {
                inputId.value = "";
                newChangeableInputValue.value = "";
                isEdit.value = false;
              }
              if (
                !target?.classList.contains("save-option-changes-button") &&
                !target?.classList.contains("n-input__input-el")
              ) {
                inputId.value = "";
                isEdit.value = false;
              }
            },
            onClick: (e: Event) => {
              e.stopPropagation();
            },
          }),
          h(
            NButton,
            {
              class: ["save-option-changes-button", "text-button"],
              onClick: (e: Event) => {
                e.stopPropagation();
                startLoader();
                onChangeOption(option as OptionsInterface);
              },
            },
            () => "Save"
          ),
        ]
      : [
          h("div", { class: "option-ellipsis" }, option.label as string),
          h(
            NDropdown,
            {
              options: rowOptions.value,
              placement: "bottom-end",
              trigger: "click",
              onClick: (e: Event) => {
                e.stopPropagation();
              },
              onClickoutside: () => {
                onFocusElement(inputRef);
              },
              onSelect: (key: string) => {
                if (key === "edit") {
                  inputId.value = option.value as string;
                  newChangeableInputValue.value = option.label as string;
                  nextTick(() => {
                    onFocusElement(changeInputRef);
                  });
                } else if (key === "delete") {
                  startLoader();
                  deleteOptionFunction(`${option.value}`)
                    .then(() => {
                      onDeleteEmit(`${option.value}`);
                    })
                    .catch(() => {
                      onFocusElement(inputRef);
                    })
                    .finally(() => {
                      finishLoader();
                    });
                }
              },
            },
            {
              default: () =>
                h(
                  NButton,
                  {
                    class: "dropdown-menu-button",
                    tabindex: -1,
                    onClick: (e: Event) => {
                      e.stopPropagation();
                    },
                  },
                  () => h(NIcon, () => h(ThreeDotMenu, { size: 18 }))
                ),
            }
          ),
        ];
  };
  return { renderLabel };
};
