import { Ref } from "vue";

export default (modalRef: Ref<HTMLElement | undefined>) => {
  const onKeydownTabHandler = (event: {
    cellRef: Ref<HTMLElement> | HTMLElement;
    shiftKey: boolean;
  }) => {
    const formItems = modalRef.value?.querySelectorAll(".tabbable-focusable");
    if (!formItems) {
      return;
    }
    const currentCell =
      (event.cellRef as Ref<HTMLElement>)?.value || event.cellRef;
    const index = Array.from(formItems).findIndex((item) => {
      return item === currentCell;
    });
    if (index === -1) {
      return;
    }
    const nextIndex = index + (event.shiftKey ? -1 : 1);
    if (!event.shiftKey && nextIndex === formItems.length) {
      navigateToNextElement(formItems[0] as HTMLElement);
      return;
    }
    if (event.shiftKey && nextIndex < 0) {
      navigateToNextElement(formItems[formItems.length - 1] as HTMLElement);
      return;
    }
    navigateToNextElement(formItems[nextIndex] as HTMLElement);
  };

  const navigateToNextElement = (nextElement: HTMLElement) => {
    if (nextElement.classList.contains("selectable-table-cell")) {
      nextElement.click();
    } else if (
      nextElement.classList.contains("n-input") ||
      nextElement.classList.contains("n-input-number")
    ) {
      nextElement.getElementsByTagName("input")[0]?.focus();
    } else if (
      nextElement.classList.contains("n-button") ||
      nextElement.classList.contains("button-focusable") ||
      nextElement.classList.contains("link-focusable")
    ) {
      nextElement.focus();
    }
  };

  const onKeydownTab = (event: KeyboardEvent) => {
    event.preventDefault();
    const cellRef = (event.target as HTMLElement).closest(
      ".tabbable-focusable"
    ) as HTMLElement;
    onKeydownTabHandler({ cellRef, shiftKey: event.shiftKey });
  };
  return {
    onKeydownTabHandler,
    navigateToNextElement,
    onKeydownTab,
  };
};
