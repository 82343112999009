import { Ref, nextTick } from "vue";
import { AxiosResponse } from "axios";
import useBaseCostMethods from "@/composables/costing-parameters/useBaseCostMethods";
import { SalesRegionInterface } from "@/types/SalesRegionInterface";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";
import AvailabilityInterface from "@/types/admin-parameters/costing-parameters/AvailabilityInterface";

export default (
  data: Ref<AvailabilityInterface[]>,
  keyField: string,
  showColumns: {
    showLeadTimeColumn: boolean;
    showCTQLeadTimeColumn: boolean;
    showMOQColumn: boolean;
    showMinCostColumn: boolean;
  },
  addRequest: (payload: BaseCostInterface[]) => Promise<AxiosResponse>,
  changeRequest: (payload: BaseCostInterface) => Promise<AxiosResponse>,
  onClickCostCell: () => void,
  onClickLeadTimeCell: () => void
) => {
  const {
    isValidMfrRegionOption,
    getDataIndexes,
    getMfrRegionOptions,
    onUpdateTable,
    valueAsNumber,
  } = useBaseCostMethods(data, showColumns);

  const areAllFieldsFilledIn = (
    available: boolean,
    costAsNumber: number,
    leadTimeAsNumber: number,
    newMinCostAsNumber: number
  ) => {
    return (
      available &&
      costAsNumber > 0 &&
      leadTimeAsNumber > 0 &&
      newMinCostAsNumber >= 0
    );
  };

  const onUpdateMinCost = (
    newMinCost: string,
    row: AvailabilityInterface,
    region: SalesRegionInterface
  ): Promise<void> | void => {
    const index = row.baseCosts.findIndex(
      (item) => item.mfrRegionId === region.id
    );
    const { available, cost, leadTime } = getMfrRegionOptions(
      row.id,
      region.id
    );
    const { dataIndex, baseCostsIndex } = getDataIndexes(row.id, region.id);
    const costAsNumber = cost ? +cost : 0;
    const leadTimeAsNumber = leadTime ? +leadTime : 0;
    const newMinCostAsNumber = +newMinCost || 0;
    if (
      !row.baseCosts[index]?.id &&
      areAllFieldsFilledIn(
        available,
        costAsNumber,
        leadTimeAsNumber,
        newMinCostAsNumber
      )
    ) {
      return addRequest([
        {
          [keyField]: row.id,
          available: true,
          leadTime: `${leadTimeAsNumber}`,
          cost: `${costAsNumber}`,
          minCost: newMinCost,
          mfrRegionId: region.id,
        },
      ]).then((res) => {
        onUpdateTable(dataIndex, baseCostsIndex, {
          ...res.data.data[0],
          mfrRegionId: region.id,
        });
      });
    }
    if (
      index !== -1 &&
      row.baseCosts[index]?.id &&
      areAllFieldsFilledIn(
        available,
        costAsNumber,
        leadTimeAsNumber,
        newMinCostAsNumber
      )
    ) {
      return changeRequest({
        [keyField]: row.id,
        id: row.baseCosts[index].id,
        available: true,
        cost: `${costAsNumber}`,
        leadTime: `${leadTimeAsNumber}`,
        minCost: newMinCost,
      }).then((res) => {
        onUpdateTable(dataIndex, baseCostsIndex, {
          ...(res.data.data || res.data),
          mfrRegionId: region.id,
        });
      });
    }
  };

  const onUpdateMinCostValue = (
    newVal: string,
    row: AvailabilityInterface,
    region: SalesRegionInterface
  ) => {
    const { dataIndex, baseCostsIndex } = getDataIndexes(row.id, region.id);
    const newMinCostAsNumber = +newVal;
    if (baseCostsIndex !== -1) {
      data.value[dataIndex].baseCosts[baseCostsIndex].minCost =
        newMinCostAsNumber;
    } else {
      data.value[dataIndex].baseCosts.push({
        cost: data.value[dataIndex].baseCosts[baseCostsIndex]?.cost || "",
        minCost: newVal,
        mfrRegionId: region.id,
      });
    }
    moveToNextCell(row.id, region.id);
  };

  const moveToNextCell = (rowId: string, regionId: string) => {
    const { leadTime, cost } = getMfrRegionOptions(rowId, regionId);

    if (isValidMfrRegionOption(rowId, regionId)) {
      return;
    }
    if (!isValidMfrRegionOption(rowId, regionId) && valueAsNumber(cost) === 0) {
      nextTick(() => {
        onClickCostCell();
      });
    } else if (valueAsNumber(leadTime) <= 0) {
      nextTick(() => {
        onClickLeadTimeCell();
      });
    }
  };

  return {
    onUpdateMinCost,
    onUpdateMinCostValue,
  };
};
