
import {
  computed,
  defineComponent,
  h,
  nextTick,
  PropType,
  ref,
  watch,
} from "vue";
import { NSelect, useLoadingBar, SelectOption, NEmpty } from "naive-ui";
import popupMessageDefaultConfig from "@/constants/popupMessageDefaultConfig";
import useSelectorRenderLabel from "@/composables/costing-parameters/useSelectorRenderLabel";
import useSelectorRenderHeader from "@/composables/costing-parameters/useSelectorRenderHeader";
import { onFocusElement } from "@/composables/onFocusElement";

export default defineComponent({
  props: {
    value: {
      type: String,
    },
    values: {
      type: Array as PropType<string[]>,
    },
    options: {
      type: Array as PropType<SelectOption[]>,
      required: true,
    },
    validateAndGetPayload: {
      type: Function,
    },
    updateFunction: {
      type: [Function],
    },
    addOptionFunction: {
      type: [Function],
    },
    changeOptionFunction: {
      type: [Function],
    },
    deleteOptionFunction: {
      type: [Function],
    },
    updateMessage: {
      type: String,
    },
    onUpdateValue: {
      type: [Function],
    },
    filterable: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
    },
    addable: {
      type: Boolean,
    },
    selectInForm: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    showDeleteOption: {
      type: Boolean,
      default: true,
    },
    onThenActionsTrigger: {
      type: Boolean,
    },
  },
  emits: [
    "addOption",
    "deleteOption",
    "changeOption",
    "editMode",
    "keydownTab",
    "keydownEsc",
  ],

  setup(props, { emit }) {
    const isEdit = ref(false);
    const isThenTriggered = ref(true);
    const isAddOption = ref(true);
    const cellRef = ref<HTMLElement>();
    const inputRef = ref<HTMLInputElement>();
    const selectRef = ref<HTMLSelectElement>();
    const selectValue = ref(props.value);
    const loading = ref(false);
    const loadingBar = useLoadingBar();

    function handleOnClick() {
      isEdit.value = true;
      isThenTriggered.value = false;
      isAddOption.value = false;
      nextTick(() => {
        onFocusElement(inputRef);
        selectValue.value = props.value;
        if (!props.filterable) {
          onFocusElement(selectRef);
        }
      });
    }

    watch(
      () => props.options,
      () => {
        selectorOptions.value = props.options;
        optionsArray.value = props.options;
      },
      { deep: true }
    );
    watch(
      () => isEdit.value,
      () => {
        emit("editMode", isEdit.value);
      }
    );
    watch(
      () => props.value,
      () => {
        selectedValues.value = props.value;
      }
    );
    watch(
      () => props.values,
      () => {
        selectedValues.value = props.values;
      }
    );
    watch(
      () => props.onThenActionsTrigger,
      () => {
        if (
          props.onThenActionsTrigger &&
          !isThenTriggered.value &&
          isHandleChangeTriggered.value
        ) {
          thenActions();
        }
      }
    );

    const isHandleChangeTriggered = ref(false);
    function handleChange() {
      const payload = props.validateAndGetPayload
        ? props.validateAndGetPayload(selectedValues.value)
        : selectedValues.value;
      if (payload === undefined) {
        onFocusElement(selectRef);
        return;
      }
      if (props.value === payload || props.values === payload) {
        isEdit.value = false;
        return;
      }
      isHandleChangeTriggered.value = true;
      if (!props.updateFunction || !props.updateFunction(payload)) {
        if (props.onUpdateValue) {
          props.onUpdateValue(payload);
        }
        isEdit.value = false;
        return;
      }
      loading.value = true;
      loadingBar.start();
      props
        .updateFunction(payload)
        .then(() => {
          if (props.updateMessage) {
            window.$message.success(
              props.updateMessage,
              popupMessageDefaultConfig
            );
          }
          isEdit.value = false;
          emit("keydownEsc");
        })
        .finally(() => {
          loading.value = false;
          isEdit.value = false;
          nextTick(() => {
            loadingBar.finish();
          });
        });
    }

    const thenActions = () => {
      isThenTriggered.value = true;
      if (props.updateMessage) {
        window.$message.success(props.updateMessage, popupMessageDefaultConfig);
      }
      isEdit.value = false;
      emit("keydownEsc");
    };
    function handleBlur(e?: FocusEvent) {
      const target = e
        ? (e.relatedTarget as HTMLElement) || (e.target as HTMLElement)
        : undefined;
      if (!target?.classList.contains("dropdown-menu-button")) {
        isEdit.value = false;
      }
    }

    const newValueKey = "new-value";
    const inputId = ref("");
    const newChangeableInputValue = ref("");
    const isChangedSelectedValues = ref(false);
    const selectedValues = ref(props.multiple ? props.values : props.value);
    const selectorOptions = ref(props.options);
    const optionsArray = ref(props.options);
    const selectorOptionsWithHeader = computed(() => {
      return [
        {
          type: "render",
          render: renderHeader(),
        },
        ...(selectorOptions.value.length === 0
          ? [
              {
                type: "render",
                render: () => h(NEmpty, { description: "No Data" }),
              },
            ]
          : []),
        ...selectorOptions.value,
      ];
    });
    const rowOptions = ref([
      ...(props.showDeleteOption
        ? [
            {
              label: "Delete",
              key: "delete",
            },
          ]
        : []),
      {
        label: "Edit",
        key: "edit",
      },
    ]);

    const { renderHeader } = useSelectorRenderHeader(
      props.selectInForm,
      props.filterable,
      props.addable,
      optionsArray,
      selectorOptions,
      isEdit,
      newValueKey,
      inputRef,
      cellRef,
      emit,
      (newValue?: string) => {
        if (props.addable && newValue) {
          props?.addOptionFunction?.(newValue);
          if (props.selectInForm) {
            props?.onUpdateValue?.(newValue);
          }
          isEdit.value = false;
        }
      }
    );
    const { renderLabel } = useSelectorRenderLabel(
      inputRef,
      inputId,
      newChangeableInputValue,
      isEdit,
      rowOptions,
      (newData) => props.changeOptionFunction?.(newData),
      (id) => props.deleteOptionFunction?.(id),
      (newData) => {
        onFocusElement(inputRef);
        emit("changeOption", newData);
      },
      (optionValue) => {
        onFocusElement(inputRef);
        emit("deleteOption", optionValue);
      }
    );
    const addableInForm = props.selectInForm && props.addable;

    return () =>
      h(
        "div",
        {
          class: [
            "selectable-table-cell",
            "tabbable-focusable",
            loading.value || props.disabled ? "disabled" : "",
            "full-height",
            isEdit.value && !props.selectInForm ? "bordered" : "",
          ],
          tabindex: 0,
          ref: cellRef,
          onClick: () => {
            if (!props.disabled) {
              handleOnClick();
            }
          },
          onKeydown: (event: KeyboardEvent) => {
            if (event.key === "Escape") {
              isEdit.value = false;
              event.stopPropagation();
            }
          },
        },
        [
          h(NSelect, {
            class: ["edit-select", props.selectInForm ? "select-in-form" : ""],
            loading: loading.value,
            options: selectorOptionsWithHeader.value as SelectOption[],
            multiple: props.multiple,
            show: isEdit.value && !props.disabled,
            showCheckmark: false,
            showArrow: false,
            consistentMenuWidth: false,
            ref: selectRef,
            value: selectedValues.value,
            placeholder: "Select",
            renderLabel: props.addable ? renderLabel : undefined,
            fallbackOption: (value: string): SelectOption => {
              return {
                label:
                  props.options.find((item) => item.value === value)?.label ||
                  "",
                value,
              };
            },
            onUpdateValue: (v: string | string[]) => {
              if (!v) {
                return;
              }
              isChangedSelectedValues.value = true;
              const addOptionFunctionPayload =
                typeof v === "string" && v.includes(newValueKey)
                  ? v.split(newValueKey)?.[1]
                  : undefined;
              if (addOptionFunctionPayload) {
                props?.addOptionFunction?.(addOptionFunctionPayload);
              } else {
                selectedValues.value =
                  typeof v === "string" ? v : v.filter((item) => !!item);
              }
              if (!props.multiple && !addOptionFunctionPayload) {
                handleChange();
              }
              if (addableInForm && addOptionFunctionPayload) {
                props?.onUpdateValue?.(addOptionFunctionPayload);
                isEdit.value = false;
              }
              isEdit.value = props.multiple;
            },
            onBlur: (e: FocusEvent) => {
              selectorOptions.value = props.options;
              if (
                props.multiple &&
                isChangedSelectedValues.value &&
                JSON.stringify(selectedValues.value) !==
                  JSON.stringify(props.values)
              ) {
                handleChange();
              } else {
                handleBlur(e);
              }
            },
            onKeydown: (e: KeyboardEvent) => {
              if (e.code === "Tab") {
                isEdit.value = false;
                emit("keydownTab", {
                  cellRef,
                  shiftKey: e.shiftKey,
                });
                e.preventDefault();
              } else if (e.code === "Escape") {
                isEdit.value = false;
                emit("keydownEsc");
              }
            },
          }),
        ]
      );
  },
});
