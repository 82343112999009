import { AxiosResponse } from "axios";
import ApiService from "@/services";
import { NewRawMaterialInterface } from "@/types/costing-parameters/NewRawMaterialInterface";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";
import { NewEquivalentSpecsInterface } from "@/types/costing-parameters/NewEquivalentSpecslInterface";
import { NewSurfaceTreatmentInterface } from "@/types/costing-parameters/NewSurfaceTreatmentlInterface";
import { NewMfgProcessInterface } from "@/types/costing-parameters/NewMfgProcessInterface";
import { NewHeatTreatmentInterface } from "@/types/costing-parameters/NewHeatTreatmentInterface";
import { BuyItemInterface } from "@/types/costing-parameters/BuyItemInterface";

export default class CostingParameterService {
  static getMfrRegions(): Promise<AxiosResponse> {
    return ApiService.get("/v1/mfr-regions");
  }

  //Raw Materials
  static getRawMaterialListWithRelations(): Promise<AxiosResponse> {
    return ApiService.get("/v2/raw-materials/with-relations");
  }
  static getRawMaterialList(params: {
    search?: string;
    skip: number;
    limit: number;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/raw-materials", { params });
  }
  static addRawMaterial(
    payload: NewRawMaterialInterface
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/raw-materials", payload);
  }
  static changeRawMaterial(payload: {
    rawMaterialId: string;
    data: Partial<NewRawMaterialInterface>;
  }): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/raw-materials/${payload.rawMaterialId}`,
      payload.data
    );
  }
  static getRawMaterialCouldBeDeleted(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/raw-materials/${id}/could-be-deleted`);
  }
  static deleteRawMaterial(rawMaterialId: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/raw-materials/${rawMaterialId}`);
  }

  static addRawMaterialAlternatives(payload: {
    rawMaterialId: string;
    alternativeMaterialIds: string[];
  }): Promise<AxiosResponse> {
    return ApiService.post(
      `/v2/raw-materials/${payload.rawMaterialId}/alternatives`,
      { alternativeMaterialIds: payload.alternativeMaterialIds }
    );
  }
  static deleteAllRawMaterialAlternatives(
    rawMaterialId: string,
    alternativeMaterialIds: string[]
  ): Promise<AxiosResponse> {
    return ApiService.delete(
      `/v2/raw-materials/${rawMaterialId}/alternatives`,
      { data: { alternativeMaterialIds } }
    );
  }

  static addRawMaterialBaseCost(
    payload: BaseCostInterface[]
  ): Promise<AxiosResponse> {
    return ApiService.post(
      `/v2/raw-materials/${payload[0].rawMaterialId}/base-costs`,
      payload
    );
  }
  static changeRawMaterialBaseCost(
    payloadWithId: BaseCostInterface
  ): Promise<AxiosResponse> {
    const { id, rawMaterialId, ...payload } = payloadWithId;
    return ApiService.patch(
      `/v2/raw-materials/${rawMaterialId}/base-costs/${id}`,
      payload
    );
  }

  //Raw Materials Group
  static getRawMaterialGroup(): Promise<AxiosResponse> {
    return ApiService.get("/v2/raw-material-groups");
  }
  static addRawMaterialGroup(name: string): Promise<AxiosResponse> {
    return ApiService.post("/v2/raw-material-groups", {
      name,
    });
  }
  static changeRawMaterialGroup(params: {
    id: string;
    name: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/raw-material-groups/${params.id}`, {
      name: params.name,
    });
  }
  static deleteRawMaterialGroup(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/raw-material-groups/${id}`);
  }

  //Equivalent materials
  static getEquivalentMaterialsById(params: {
    skip: number;
    limit: number;
    standardName?: string;
    relativeStandardName?: string;
    rawMaterialId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/raw-material-equivalent-specs", { params });
  }
  static getEquivalentMaterialListWithRelations(): Promise<AxiosResponse> {
    return ApiService.get("/v2/raw-material-equivalent-specs/with-relations");
  }
  static addEquivalentMaterials(
    payload: NewEquivalentSpecsInterface[]
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/raw-material-equivalent-specs", payload);
  }
  static changeEquivalentMaterials(params: {
    id: string;
    relativeStandardName: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/raw-material-equivalent-specs/${params.id}`, {
      relativeStandardName: params.relativeStandardName,
    });
  }
  static deleteEquivalentMaterial(
    equivalentSpecIds: string[]
  ): Promise<AxiosResponse> {
    return ApiService.delete("/v2/raw-material-equivalent-specs", {
      params: { equivalentSpecIds },
    });
  }

  //Surface finish
  static getSurfaceFinishListWithRelations(params: {
    skip: number;
    limit: number;
    mfrRegionId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/surface-finish-process/with-relations", {
      params,
    });
  }
  static addSurfaceFinish(name: string): Promise<AxiosResponse> {
    return ApiService.post("/v2/surface-finish-process", {
      name,
    });
  }
  static changeSurfaceFinish(params: {
    id: string;
    name: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/surface-finish-process/${params.id}`, {
      name: params.name,
    });
  }
  static addSurfaceFinishBaseCost(
    payload: BaseCostInterface[]
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/surface-finish-process-base-cost", {
      baseCosts: payload,
    });
  }
  static changeSurfaceFinishBaseCost(
    payloadWithId: BaseCostInterface
  ): Promise<AxiosResponse> {
    const { id, surfaceFinishProcessId, ...payload } = payloadWithId;
    return ApiService.patch(
      `/v2/surface-finish-process-base-cost/${id}`,
      payload
    );
  }
  static deleteSurfaceFinish(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/surface-finish-process/${id}`);
  }

  //Surface treatment
  static getSurfaceTreatmentListWithRelations(): Promise<AxiosResponse> {
    return ApiService.get("/v2/surface-treatment-processes/with-relations");
  }
  static getSurfaceTreatmentList(params: {
    name?: string;
    skip?: number;
    limit?: number;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/surface-treatment-processes", { params });
  }
  static addSurfaceTreatment(
    payload: NewSurfaceTreatmentInterface
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/surface-treatment-processes", payload);
  }
  static addSurfaceTreatmentBaseCost(
    payload: BaseCostInterface[]
  ): Promise<AxiosResponse> {
    return ApiService.post(
      `/v2/surface-treatment-processes/${payload[0].surfaceTreatmentProcessId}/base-costs`,
      payload
    );
  }
  static changeSurfaceTreatmentBaseCost(
    payload: BaseCostInterface
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/surface-treatment-processes/${payload.surfaceTreatmentProcessId}/base-costs/${payload.id}`,
      {
        available: payload.available,
        cost: payload.cost,
        leadTime: payload.leadTime,
        minCost: payload.minCost,
      }
    );
  }
  static changeSurfaceTreatment(payload: {
    surfaceTreatmentProcessId: string;
    data: Partial<NewSurfaceTreatmentInterface>;
  }): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/surface-treatment-processes/${payload.surfaceTreatmentProcessId}`,
      payload.data
    );
  }
  static deleteSurfaceTreatment(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/surface-treatment-processes/${id}`);
  }

  static addSurfaceTreatmentAlternatives(
    surfaceTreatmentProcessId: string,
    materialGroupIds: string[]
  ): Promise<AxiosResponse> {
    return ApiService.post(
      `/v2/surface-treatment-processes/${surfaceTreatmentProcessId}/alternatives`,
      { materialGroupIds }
    );
  }
  static deleteSurfaceTreatmentAlternatives(
    surfaceTreatmentProcessId: string
  ): Promise<AxiosResponse> {
    return ApiService.delete(
      `/v2/surface-treatment-processes/${surfaceTreatmentProcessId}/alternatives`
    );
  }

  static getSurfaceTreatmentColors(): Promise<AxiosResponse> {
    return ApiService.get("/v2/surface-treatment-colors");
  }
  static addSurfaceTreatmentColor(name: string): Promise<AxiosResponse> {
    return ApiService.post("/v2/surface-treatment-colors", {
      name,
    });
  }
  static changeSurfaceTreatmentColor(params: {
    id: string;
    name: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/surface-treatment-colors/${params.id}`, {
      name: params.name,
    });
  }
  static deleteSurfaceTreatmentColor(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/surface-treatment-colors/${id}`);
  }

  //Buy items
  static addBuyItems(payload: BuyItemInterface): Promise<AxiosResponse> {
    return ApiService.post("/v2/buy-item-processes", payload);
  }
  static getBuyItemProcesses(params: {
    search?: string;
    skip: number;
    limit: number;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/buy-item-processes/with-relations", { params });
  }
  static addBuyItemBaseCost(
    payload: BaseCostInterface
  ): Promise<AxiosResponse> {
    return ApiService.post(`/v2/buy-item-process-base-costs`, payload);
  }
  static changeBuyItemBaseCost(
    payload: BaseCostInterface
  ): Promise<AxiosResponse> {
    const { buyItemProcessId, id, ...data } = payload;
    return ApiService.patch(`/v2/buy-item-process-base-costs/${id}`, data);
  }
  static getBuyItemProcess(params: {
    search?: string;
    skip: number;
    limit: number;
    select: string[];
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/buy-item-processes", { params });
  }
  static changeBuyItem(payload: {
    buyItemProcessId: string;
    data: BuyItemInterface;
  }): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/buy-item-processes/${payload.buyItemProcessId}`,
      payload.data
    );
  }
  static getBuyItemProcessOptions(): Promise<AxiosResponse> {
    return ApiService.get("/v2/buy-item-processes/options");
  }
  static changeBuyItemOptions(payload: {
    property: string;
    oldValue: string;
    newValue: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch("/v2/buy-item-processes/bulk", payload);
  }
  static deleteBuyItem(buyItemId: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/buy-item-processes/${buyItemId}`);
  }

  //mfg process
  static getMfgProcessListWithRelations(params: {
    skip: number;
    limit: number;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/manufacturing-processes/with-relations", {
      params,
    });
  }
  static getMfgProcessOptions(): Promise<AxiosResponse> {
    return ApiService.get("/v2/manufacturing-processes/options");
  }
  static getMfgProcessById(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/manufacturing-processes/${id}`);
  }
  static addMfgProcess(
    payload: NewMfgProcessInterface
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/manufacturing-processes", payload);
  }
  static changeMfgProcess(payload: {
    id: string;
    data: Partial<NewMfgProcessInterface>;
  }): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/manufacturing-processes/${payload.id}`,
      payload.data
    );
  }
  static changeMfgProcessOptions(payload: {
    property: string;
    oldValue: string;
    newValue: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch("/v2/manufacturing-processes/bulk", payload);
  }
  static addMfgProcessBaseCost(
    payload: BaseCostInterface[]
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/manufacturing-process-base-costs", {
      baseCosts: payload,
    });
  }
  static changeMfgProcessBaseCost(
    payloadWithId: BaseCostInterface
  ): Promise<AxiosResponse> {
    const { id, productionProcessId, ...payload } = payloadWithId;
    return ApiService.patch(
      `/v2/manufacturing-process-base-costs/${id}`,
      payload
    );
  }
  static deleteMfgProcess(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/manufacturing-processes/${id}`);
  }

  //Administrative
  static getAdministrativeListWithRelations(params: {
    skip: number;
    limit: number;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/administrative-processes/with-relations", {
      params,
    });
  }
  static addAdministrative(productionTech: string): Promise<AxiosResponse> {
    return ApiService.post("/v2/administrative-processes", {
      productionTech,
    });
  }
  static changeAdministrative(
    id: string,
    productionTech: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/administrative-processes/${id}`, {
      productionTech,
    });
  }
  static addAdministrativeBaseCost(
    payload: BaseCostInterface[]
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/administrative-process-base-costs", {
      baseCosts: payload,
    });
  }
  static changeAdministrativeBaseCost(
    payloadWithId: BaseCostInterface
  ): Promise<AxiosResponse> {
    const { id, administrativeProcessId, ...payload } = payloadWithId;
    return ApiService.patch(
      `/v2/administrative-process-base-costs/${id}`,
      payload
    );
  }
  static deleteAdministrative(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/administrative-processes/${id}`);
  }

  //Heat treatment
  static getHeatTreatmentListWithRelations(params: {
    skip: number;
    limit: number;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/heat-treatment-processes/with-relations", {
      params,
    });
  }
  static getHeatTreatmentList(): Promise<AxiosResponse> {
    return ApiService.get("/v2/heat-treatment-processes");
  }
  static addHeatTreatment(payload: {
    name: string;
    standardId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v2/heat-treatment-processes", payload);
  }
  static changeHeatTreatment(payload: {
    heatTreatmentProcessId: string;
    data: Partial<NewHeatTreatmentInterface>;
  }): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/heat-treatment-processes/${payload.heatTreatmentProcessId}`,
      payload.data
    );
  }
  static deleteHeatTreatment(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/heat-treatment-processes/${id}`);
  }
  static addHeatTreatmentBaseCost(
    payload: BaseCostInterface[]
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/heat-treatment-processes/base-costs", payload);
  }
  static changeHeatTreatmentBaseCost(
    payloadWithId: BaseCostInterface
  ): Promise<AxiosResponse> {
    const { id, heatTreatmentProcessId, ...payload } = payloadWithId;
    return ApiService.patch(
      `/v2/heat-treatment-processes/base-costs/${id}`,
      payload
    );
  }
  static getHeatTreatmentStandards(): Promise<AxiosResponse> {
    return ApiService.get("/v2/heat-treatment-processes/standards");
  }
  static addHeatTreatmentStandard(name: string): Promise<AxiosResponse> {
    return ApiService.post("/v2/heat-treatment-processes/standards", {
      name,
    });
  }
  static changeHeatTreatmentStandard(params: {
    id: string;
    name: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/heat-treatment-processes/standards/${params.id}`,
      {
        name: params.name,
      }
    );
  }
  static deleteHeatTreatmentStandard(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/heat-treatment-processes/standards/${id}`);
  }

  static addHeatTreatmentAlternatives(
    params: { heatTreatmentProcessId: string; materialGroupId: string }[]
  ): Promise<AxiosResponse> {
    return ApiService.post(
      "/v2/heat-treatment-processes/material-group-heat-treatment",
      params
    );
  }
  static deleteHeatTreatmentAlternatives(id: string): Promise<AxiosResponse> {
    return ApiService.delete(
      `/v2/heat-treatment-processes/material-group-heat-treatment/${id}`
    );
  }

  //Quality control
  static getQualityControlListWithRelations(params: {
    skip: number;
    limit: number;
    mfrRegionId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/quality-control-processes/with-relations", {
      params,
    });
  }
  static addQualityControl(processName: string): Promise<AxiosResponse> {
    return ApiService.post("/v2/quality-control-processes", {
      processName,
    });
  }
  static changeQualityControl(params: {
    id: string;
    processName: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/quality-control-processes/${params.id}`, {
      processName: params.processName,
    });
  }
  static addQualityControlBaseCost(
    payload: BaseCostInterface[]
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/quality-control-process-base-costs", {
      baseCosts: payload,
    });
  }
  static changeQualityControlBaseCost(
    payloadWithId: BaseCostInterface
  ): Promise<AxiosResponse> {
    const { id, qualityControlProcessId, ...payload } = payloadWithId;
    return ApiService.patch(
      `/v2/quality-control-process-base-costs/${id}`,
      payload
    );
  }
  static deleteQualityControl(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/quality-control-processes/${id}`);
  }
}
