import { h } from "vue";

export default () => {
  const renderSorterIcon = (payload: { order: string | boolean }) => {
    if (!payload.order) {
      return h("div", { class: "sort-triangle disabled" });
    } else {
      return h("div", { class: `sort-triangle ${payload.order}` });
    }
  };
  return {
    renderSorterIcon,
  };
};
