import { Ref, nextTick } from "vue";
import { AxiosResponse } from "axios";
import useBaseCostMethods from "@/composables/costing-parameters/useBaseCostMethods";
import { SalesRegionInterface } from "@/types/SalesRegionInterface";
import {
  BaseCostCellType,
  BaseCostInterface,
} from "@/types/costing-parameters/BaseCostInterface";
import AvailabilityInterface from "@/types/admin-parameters/costing-parameters/AvailabilityInterface";

export default (
  data: Ref<AvailabilityInterface[]>,
  keyField: string,
  showColumns: {
    showLeadTimeColumn: boolean;
    showCTQLeadTimeColumn: boolean;
    showMOQColumn: boolean;
    showMinCostColumn: boolean;
  },
  addRequest: (payload: BaseCostInterface[]) => Promise<AxiosResponse>,
  changeRequest: (payload: BaseCostInterface) => Promise<AxiosResponse>,
  onClickCostCell: () => void,
  onClickCTQDimLeadTimeCell?: () => void,
  onClickMOQCell?: () => void
) => {
  const {
    isValidMfrRegionOption,
    getDataIndexes,
    getMfrRegionOptions,
    onUpdateTable,
    valueAsNumber,
    isValidMOQValue,
  } = useBaseCostMethods(data, showColumns);

  const checkCTQDimLeadTime = (ctqDimLeadTime?: string | null) => {
    return showColumns.showCTQLeadTimeColumn
      ? valueAsNumber(ctqDimLeadTime) > 0
      : true;
  };

  const checkMinCost = (isMinCostAvailable: boolean) => {
    return showColumns.showMinCostColumn ? isMinCostAvailable : true;
  };

  const areAdditionalFieldsValid = (
    MOQ: BaseCostCellType | undefined,
    isMinCostAvailable: boolean
  ) => isValidMOQValue(MOQ) && checkMinCost(isMinCostAvailable);

  const areAllFieldsFilledIn = (
    available: boolean,
    isCostAvailable: boolean,
    isMinCostAvailable: boolean,
    MOQ?: BaseCostCellType,
    ctqDimLeadTime?: string | null
  ) => {
    return (
      available &&
      isCostAvailable &&
      checkCTQDimLeadTime(ctqDimLeadTime) &&
      areAdditionalFieldsValid(MOQ, isMinCostAvailable)
    );
  };

  const allowedToAddNewBaseCost = (
    rowId: string,
    regionId: string,
    baseCostId?: string
  ) => {
    const {
      available,
      ctqDimLeadTime,
      MOQ,
      isCostAvailable,
      isMinCostAvailable,
    } = getMfrRegionOptions(rowId, regionId);
    return (
      !baseCostId &&
      areAllFieldsFilledIn(
        available,
        isCostAvailable,
        isMinCostAvailable,
        MOQ,
        ctqDimLeadTime
      )
    );
  };

  const allowedToChangeExistingBaseCost = (
    rowId: string,
    regionId: string,
    baseCostId?: string
  ) => {
    const {
      available,
      ctqDimLeadTime,
      MOQ,
      isCostAvailable,
      isMinCostAvailable,
    } = getMfrRegionOptions(rowId, regionId);
    return (
      baseCostId &&
      areAllFieldsFilledIn(
        available,
        isCostAvailable,
        isMinCostAvailable,
        MOQ,
        ctqDimLeadTime
      )
    );
  };

  const getAddRequestPayload = (
    rowId: string,
    regionId: string,
    newLeadTime: string
  ) => {
    const { cost, ctqDimLeadTime, MOQ, minCost } = getMfrRegionOptions(
      rowId,
      regionId
    );

    return [
      keyField === "qualityControlProcessId"
        ? {
            [keyField]: rowId,
            available: true,
            costPerHour: `${cost}`,
            dimLeadTime: `${valueAsNumber(newLeadTime)}`,
            ctqDimLeadTime: `${ctqDimLeadTime}`,
            mfrRegionId: regionId,
          }
        : {
            [keyField]: rowId,
            available: true,
            leadTime: `${valueAsNumber(newLeadTime)}`,
            cost: `${cost}`,
            mfrRegionId: regionId,
            ...(showColumns.showMOQColumn ? { MOQ: valueAsNumber(MOQ) } : {}),
            ...(showColumns.showMinCostColumn
              ? { minCost: `${minCost || 0}` }
              : {}),
          },
    ];
  };

  const getChangeRequestPayload = (
    rowId: string,
    regionId: string,
    newLeadTime: string,
    id?: string
  ) => {
    const { cost, ctqDimLeadTime, MOQ, minCost } = getMfrRegionOptions(
      rowId,
      regionId
    );

    return keyField === "qualityControlProcessId"
      ? {
          [keyField]: rowId,
          id,
          available: true,
          costPerHour: `${cost}`,
          dimLeadTime: `${valueAsNumber(newLeadTime)}`,
          ctqDimLeadTime: `${ctqDimLeadTime}`,
        }
      : {
          [keyField]: rowId,
          id,
          available: true,
          cost: `${cost}`,
          leadTime: `${valueAsNumber(newLeadTime)}`,
          ...(showColumns.showMOQColumn ? { MOQ: valueAsNumber(MOQ) } : {}),
          ...(showColumns.showMinCostColumn
            ? { minCost: `${minCost || 0}` }
            : {}),
        };
  };

  const moveToNextCell = (rowId: string, regionId: string) => {
    const { cost, ctqDimLeadTime, MOQ } = getMfrRegionOptions(rowId, regionId);
    if (isValidMfrRegionOption(rowId, regionId)) {
      return;
    }
    if (!isValidMOQValue(MOQ) && onClickMOQCell) {
      nextTick(() => {
        onClickMOQCell();
      });
    } else if (
      showColumns.showCTQLeadTimeColumn &&
      valueAsNumber(ctqDimLeadTime) === 0 &&
      onClickCTQDimLeadTimeCell
    ) {
      nextTick(() => {
        onClickCTQDimLeadTimeCell();
      });
    } else if (valueAsNumber(cost) === 0) {
      nextTick(() => {
        onClickCostCell();
      });
    }
  };

  const onUpdateLeadTime = (
    newLeadTime: string,
    row: AvailabilityInterface,
    region: SalesRegionInterface
  ): Promise<void> | void => {
    const index = row.baseCosts.findIndex(
      (item) => item.mfrRegionId === region.id
    );
    const baseCostId = row.baseCosts[index]?.id;
    const { dataIndex, baseCostsIndex } = getDataIndexes(row.id, region.id);

    if (allowedToAddNewBaseCost(row.id, region.id, baseCostId)) {
      return addRequest(
        getAddRequestPayload(row.id, region.id, newLeadTime)
      ).then((res) => {
        onUpdateTable(dataIndex, baseCostsIndex, {
          ...(res.data.data ? res.data.data[0] : res.data[0]),
          mfrRegionId: region.id,
        });
      });
    }
    if (allowedToChangeExistingBaseCost(row.id, region.id, baseCostId)) {
      return changeRequest(
        getChangeRequestPayload(row.id, region.id, newLeadTime, baseCostId)
      ).then((res) => {
        onUpdateTable(dataIndex, baseCostsIndex, {
          ...(res.data.data || res.data),
          mfrRegionId: region.id,
        });
      });
    }
  };

  const onUpdateLeadTimeValue = (
    newVal: string,
    row: AvailabilityInterface,
    region: SalesRegionInterface
  ) => {
    const { dataIndex, baseCostsIndex } = getDataIndexes(row.id, region.id);

    if (baseCostsIndex !== -1 && showColumns.showCTQLeadTimeColumn) {
      data.value[dataIndex].baseCosts[
        baseCostsIndex
      ].dimLeadTime = `${valueAsNumber(newVal)}`;
    } else if (baseCostsIndex !== -1 && !showColumns.showCTQLeadTimeColumn) {
      data.value[dataIndex].baseCosts[
        baseCostsIndex
      ].leadTime = `${valueAsNumber(newVal)}`;
    } else {
      data.value[dataIndex].baseCosts.push(
        showColumns.showCTQLeadTimeColumn
          ? {
              costPerHour:
                data.value[dataIndex].baseCosts[baseCostsIndex]?.costPerHour ||
                "",
              dimLeadTime: `${valueAsNumber(newVal)}`,
              ctqDimLeadTime:
                data.value[dataIndex].baseCosts[baseCostsIndex]
                  ?.ctqDimLeadTime || "",
              mfrRegionId: region.id,
            }
          : {
              cost: data.value[dataIndex].baseCosts[baseCostsIndex]?.cost || "",
              leadTime: `${valueAsNumber(newVal)}`,
              mfrRegionId: region.id,
            }
      );
    }
    moveToNextCell(row.id, region.id);
  };

  return {
    onUpdateLeadTime,
    onUpdateLeadTimeValue,
  };
};
