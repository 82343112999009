import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77ade13e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "costing-parameter-details mfg-process-parameters",
  ref: "materialParametersWrapperRef"
}
const _hoisted_2 = {
  key: 2,
  class: "costing-parameter-details--page-counters",
  id: "costing-parameters-pagination"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Loader"], {
      loading: $setup.initLoading || $setup.loading
    }, null, 8, ["loading"]),
    ($setup.showCannotDeleteModal)
      ? (_openBlock(), _createBlock($setup["DefaultModal"], {
          key: 0,
          class: "info-modal cannot-delete-modal",
          show: $setup.showCannotDeleteModal,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($setup.showCannotDeleteModal) = $event)),
          title: "Cannot Delete",
          text: "You cannot delete costing parameter that is connected to existing parts costing.",
          infoModal: true,
          onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.showCannotDeleteModal = false))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    ($setup.showAddMfgProcessModal)
      ? (_openBlock(), _createBlock($setup["AddMfgProcessModal"], {
          key: 1,
          show: $setup.showAddMfgProcessModal,
          "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (($setup.showAddMfgProcessModal) = $event)),
          mfrRegions: $setup.mfrRegions,
          productionTechOptions: $setup.productionTechOptions,
          processNameOptions: $setup.processNameOptions,
          subTechOptions: $setup.subTechOptions,
          onUpdate: $setup.fetchData,
          onClose: $setup.closeAddMfgProcessModal,
          onUpdateOptions: $setup.getMfgProcessOptions
        }, null, 8, ["show", "mfrRegions", "productionTechOptions", "processNameOptions", "subTechOptions"]))
      : _createCommentVNode("", true),
    _createVNode($setup["CostingParametersOptions"], {
      showAddLine: true,
      onSelectPlusOption: $setup.onSelectPlusOption
    }),
    _createElementVNode("div", {
      class: "costing-parameters-table-wrapper",
      ref: "tableRef",
      style: _normalizeStyle({minHeight: `${$setup.maxHeight}px`})
    }, [
      (!$setup.initLoading)
        ? (_openBlock(), _createBlock($setup["NDataTable"], {
            key: 0,
            class: _normalizeClass(["mfg-processes costing-parameters-table", {'disable-scroll': $setup.validationError || $setup.isEditModeCell}]),
            columns: $setup.columns,
            data: $setup.data,
            "single-line": false,
            "max-height": $setup.maxHeight - $setup.tableHeaderHeight,
            style: _normalizeStyle({height: `${$setup.maxHeight}px`}),
            "onUpdate:sorter": $setup.handleSorterChange
          }, null, 8, ["columns", "data", "max-height", "style", "class", "onUpdate:sorter"]))
        : _createCommentVNode("", true)
    ], 4),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("div", { class: "costing-parameter-details--counter" }, _toDisplayString($setup.paginationCurrentItemsMessage), 513), [
            [_vShow, !$setup.initLoading]
          ]),
          _withDirectives(_createVNode($setup["NPagination"], {
            class: "costing-parameter-details--pagination",
            page: $setup.currentPage,
            "onUpdate:page": [
              _cache[3] || (_cache[3] = ($event: any) => (($setup.currentPage) = $event)),
              _cache[4] || (_cache[4] = ($event: any) => ($setup.onPaginationUpdated()))
            ],
            "page-count": $setup.totalPages,
            "page-slot": 6
          }, null, 8, ["page", "page-count"]), [
            [_vShow, $setup.totalPages > 1]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 512))
}