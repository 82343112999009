import { createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-149c6987"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "wrapper",
  ref: "modalRef"
}
const _hoisted_2 = { class: "main-form" }
const _hoisted_3 = { class: "main-form" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = {
  key: 0,
  class: "cost-and-lead-time-info"
}
const _hoisted_6 = { class: "add-raw-material-modal-actions costing-parameters-action-modal-actions main-modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "add-raw-material-modal costing-parameters-action-modal",
    preset: "dialog",
    "show-icon": false,
    title: "Add Mfg. processes",
    onMaskClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close', $setup.isUpdateTable)))
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["NForm"], {
          class: "add-raw-material-form costing-parameters-action-form",
          ref: "formRef",
          model: $setup.form
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode($setup["NFormItem"], {
                required: "",
                path: "processName",
                label: "Process name",
                rule: $setup.requiredRules('Process name', $setup.MfgProcessPropertyEnum.PROCESS_NAME)
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["SelectableTableCell"], {
                    value: $setup.form.processName,
                    options: $setup.processNameOptions,
                    addOptionFunction: (data) => $setup.onAddOptionFunction(data, $setup.MfgProcessPropertyEnum.PROCESS_NAME, $setup.processNameOptions),
                    changeOptionFunction: (data) => $setup.changeOptionFunction(data, $setup.MfgProcessPropertyEnum.PROCESS_NAME),
                    showDeleteOption: false,
                    onUpdateValue: (data) => $setup.form.processName = data,
                    filterable: "",
                    addable: "",
                    selectInForm: "",
                    onKeydownTab: $setup.onKeydownTabHandler
                  }, null, 8, ["value", "options", "addOptionFunction", "changeOptionFunction", "onUpdateValue", "onKeydownTab"])
                ]),
                _: 1
              }, 8, ["rule"]),
              _createVNode($setup["NFormItem"], {
                required: "",
                path: "subTech",
                label: "Sub tech",
                rule: $setup.requiredRules('Sub tech', $setup.MfgProcessPropertyEnum.SUB_TECH)
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["SelectableTableCell"], {
                    value: $setup.form.subTech,
                    options: $setup.subTechOptions,
                    addOptionFunction: (data) => $setup.onAddOptionFunction(data, $setup.MfgProcessPropertyEnum.SUB_TECH, $setup.subTechOptions),
                    changeOptionFunction: (data) => $setup.changeOptionFunction(data, $setup.MfgProcessPropertyEnum.SUB_TECH),
                    showDeleteOption: false,
                    onUpdateValue: (data) => $setup.form.subTech = data,
                    filterable: "",
                    addable: "",
                    selectInForm: "",
                    onKeydownTab: $setup.onKeydownTabHandler
                  }, null, 8, ["value", "options", "addOptionFunction", "changeOptionFunction", "onUpdateValue", "onKeydownTab"])
                ]),
                _: 1
              }, 8, ["rule"]),
              _createVNode($setup["NFormItem"], {
                required: "",
                path: "productionTech",
                label: "Production tech",
                rule: $setup.requiredRules('Production tech', $setup.MfgProcessPropertyEnum.PRODUCTION_TECH)
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["SelectableTableCell"], {
                    value: $setup.form.productionTech,
                    options: $setup.productionTechOptions,
                    addOptionFunction: (data) => $setup.onAddOptionFunction(data, $setup.MfgProcessPropertyEnum.PRODUCTION_TECH, $setup.productionTechOptions),
                    changeOptionFunction: (data) => $setup.changeOptionFunction(data, $setup.MfgProcessPropertyEnum.PRODUCTION_TECH),
                    showDeleteOption: false,
                    onUpdateValue: (data) => $setup.form.productionTech = data,
                    filterable: "",
                    addable: "",
                    selectInForm: "",
                    onKeydownTab: $setup.onKeydownTabHandler
                  }, null, 8, ["value", "options", "addOptionFunction", "changeOptionFunction", "onUpdateValue", "onKeydownTab"])
                ]),
                _: 1
              }, 8, ["rule"]),
              _createVNode($setup["NFormItem"], {
                path: "machineSize",
                label: "Machine size (optional)"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["NInputNumber"], {
                    class: "tabbable-focusable",
                    value: $setup.form.machineSize,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.machineSize) = $event)),
                    placeholder: "Enter",
                    showButton: false,
                    onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
                  }, null, 8, ["value", "onKeydown"])
                ]),
                _: 1
              }),
              _createVNode($setup["NFormItem"], {
                path: "accuracyCode",
                label: "Accuracy level (optional)"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["SelectableTableCell"], {
                    value: $setup.form.accuracyCode,
                    options: $setup.accuracyLevelOptions,
                    onUpdateValue: (data) => $setup.form.accuracyCode = data,
                    selectInForm: "",
                    onKeydownTab: $setup.onKeydownTabHandler
                  }, null, 8, ["value", "options", "onUpdateValue", "onKeydownTab"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["model"]),
        _createVNode($setup["NForm"], {
          class: "mfr-regions-form costing-parameters-action-form",
          ref: "formMfrRegionsRef",
          model: $setup.formMfrRegions
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.formMfrRegions, (value, key) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(key) + " manufacturing region", 1),
                _createVNode($setup["NFormItem"], {
                  path: `${key}.available`,
                  label: "Availability"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["SelectableTableCell"], {
                      value: $setup.formMfrRegions[key].available ? 'Yes' : 'No',
                      options: $setup.availabilityOptions,
                      onUpdateValue: (data) => $setup.formMfrRegions[key].available = data === $setup.AvailabilityValueEnum.Yes,
                      selectInForm: "",
                      onKeydownTab: $setup.onKeydownTabHandler
                    }, null, 8, ["value", "options", "onUpdateValue", "onKeydownTab"])
                  ]),
                  _: 2
                }, 1032, ["path"]),
                (value.available)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode($setup["NFormItem"], {
                        path: `${key}.cost`,
                        required: "",
                        label: `Cost per Hour (${$setup.formMfrRegions[key].mfrRegionCurrencySign})`,
                        rule: $setup.baseCostNumberFieldRules(`${key} cost`)
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["NInputNumber"], {
                            class: "tabbable-focusable",
                            value: $setup.formMfrRegions[key].cost,
                            "onUpdate:value": ($event: any) => (($setup.formMfrRegions[key].cost) = $event),
                            placeholder: "Enter",
                            showButton: false,
                            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
                          }, {
                            prefix: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.formMfrRegions[key].mfrRegionCurrencySign), 1)
                            ]),
                            _: 2
                          }, 1032, ["value", "onUpdate:value", "onKeydown"])
                        ]),
                        _: 2
                      }, 1032, ["path", "label", "rule"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 256))
          ]),
          _: 1
        }, 8, ["model"]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode($setup["NButton"], {
            class: "update-button n-button-save medium-button tabbable-focusable",
            type: "info",
            onClick: $setup.onAddMaterial,
            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
          }, {
            default: _withCtx(() => [
              _createTextVNode("Add")
            ]),
            _: 1
          }, 8, ["onKeydown"]),
          _createVNode($setup["NButton"], {
            class: "medium-button n-button-cancel tabbable-focusable",
            type: "tertiary",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close', $setup.isUpdateTable))),
            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }, 8, ["onKeydown"])
        ])
      ], 512)
    ]),
    _: 1
  }))
}