import { computed, ref, Ref } from "vue";
import { useStore } from "vuex";

export default (isEditModeCell: Ref<boolean>, loading: Ref<boolean>) => {
  const store = useStore();
  const isCostingParametersViewerRole =
    store.getters["user/isCostingParametersViewerRole"];
  const activeCellId = ref("");
  const activeCellKey = ref("");

  const disableTable = computed(
    () => isCostingParametersViewerRole || isEditModeCell.value || loading.value
  );
  const disableCellValue = (rowId: string, rowKey: string) => {
    return (
      disableTable.value &&
      (activeCellId.value !== rowId || activeCellKey.value !== rowKey)
    );
  };
  const onSetActiveCell = (rowId: string, rowKey: string) => {
    if (
      disableTable.value &&
      (activeCellId.value !== rowId || activeCellKey.value !== rowKey)
    ) {
      return;
    }
    activeCellId.value = rowId;
    activeCellKey.value = rowKey;
  };

  const numberFieldError = ref(false);
  const validationFieldName = ref("");
  const validateAndGetNumberPayload = (
    inputValue: string,
    validationField = ""
  ) => {
    numberFieldError.value = +inputValue <= 0 || isNaN(+inputValue);
    validationFieldName.value = numberFieldError.value ? validationField : "";
    return !numberFieldError.value ? inputValue : undefined;
  };

  const validateAndGetNotNegativeNumberPayload = (
    inputValue: string,
    validationField = ""
  ) => {
    numberFieldError.value = +inputValue < 0 || isNaN(+inputValue);
    validationFieldName.value = numberFieldError.value ? validationField : "";
    return !numberFieldError.value ? inputValue : undefined;
  };

  const requiredFieldError = ref(false);
  const validateAndGetRequiredPayload = (inputValue: string | string[]) => {
    requiredFieldError.value = !inputValue || !inputValue?.length;
    return !inputValue || !inputValue?.length ? undefined : inputValue;
  };

  return {
    isCostingParametersViewerRole,
    activeCellId,
    activeCellKey,
    disableTable,
    disableCellValue,
    onSetActiveCell,
    numberFieldError,
    validationFieldName,
    validateAndGetNumberPayload,
    requiredFieldError,
    validateAndGetRequiredPayload,
    validateAndGetNotNegativeNumberPayload,
  };
};
