import { Ref, ref } from "vue";
import {
  BaseCostCellType,
  BaseCostInterface,
} from "@/types/costing-parameters/BaseCostInterface";
import AvailabilityInterface from "@/types/admin-parameters/costing-parameters/AvailabilityInterface";

export default (
  data: Ref<AvailabilityInterface[]>,
  showColumns: {
    showLeadTimeColumn: boolean;
    showCTQLeadTimeColumn: boolean;
    showMOQColumn: boolean;
    showMinCostColumn: boolean;
  }
) => {
  const baseCostValidationError = ref(false);

  const valueAsNumber = (value?: BaseCostCellType) => {
    return value ? +value : 0;
  };

  const isValidMOQValue = (MOQ?: BaseCostCellType) => {
    return (
      !showColumns.showMOQColumn ||
      (showColumns.showMOQColumn && valueAsNumber(MOQ) > 0)
    );
  };

  const isValidMinCostValue = (minCost?: BaseCostCellType) => {
    return (
      !showColumns.showMinCostColumn ||
      (showColumns.showMinCostColumn && valueAsNumber(minCost) >= 0)
    );
  };

  const isValidLeadTimeValue = (isLeadTimeAvailable: boolean) => {
    return (
      !showColumns.showLeadTimeColumn ||
      (showColumns.showLeadTimeColumn && isLeadTimeAvailable)
    );
  };

  const areAllQualityControlFieldsFilledIn = (
    available: boolean,
    isCostAvailable: boolean,
    isDimLeadTimeAvailable: boolean,
    isCTQDimLeadTimeAvailable: boolean
  ) => {
    return (
      available &&
      isCostAvailable &&
      isDimLeadTimeAvailable &&
      isCTQDimLeadTimeAvailable
    );
  };

  const areAdditionalFieldsValid = (
    MOQ?: number | null,
    minCost?: BaseCostCellType
  ) => isValidMOQValue(MOQ) && isValidMinCostValue(minCost);

  const areAllFieldsFilledIn = (
    available: boolean,
    isCostAvailable: boolean,
    isLeadTimeAvailable: boolean,
    MOQ?: number | null,
    minCost?: BaseCostCellType
  ) => {
    return (
      available &&
      isCostAvailable &&
      isValidLeadTimeValue(isLeadTimeAvailable) &&
      areAdditionalFieldsValid(MOQ, minCost)
    );
  };

  const isValidMfrRegionOption = (rowId: string, regionId: string) => {
    const {
      available,
      cost,
      MOQ,
      minCost,
      isCostAvailable,
      isLeadTimeAvailable,
      isDimLeadTimeAvailable,
      isCTQDimLeadTimeAvailable,
    } = getMfrRegionOptions(rowId, regionId);

    if (!showColumns.showLeadTimeColumn) {
      return !available || (available && valueAsNumber(cost) > 0);
    }
    if (showColumns.showCTQLeadTimeColumn) {
      return (
        !available ||
        areAllQualityControlFieldsFilledIn(
          available,
          isCostAvailable,
          isDimLeadTimeAvailable,
          isCTQDimLeadTimeAvailable
        )
      );
    }
    return (
      !available ||
      areAllFieldsFilledIn(
        available,
        isCostAvailable,
        isLeadTimeAvailable,
        MOQ,
        minCost
      )
    );
  };

  const getDataIndexes = (rowId: string, regionId: string) => {
    const dataIndex = data.value.findIndex(
      (item: AvailabilityInterface) => item.id === rowId
    );
    const baseCostsIndex =
      dataIndex !== -1
        ? data.value[dataIndex].baseCosts.findIndex(
            (item) => item.mfrRegionId === regionId
          )
        : -1;
    return { dataIndex, baseCostsIndex };
  };

  const getMfrRegionOptions = (rowId: string, regionId: string) => {
    const { dataIndex, baseCostsIndex } = getDataIndexes(rowId, regionId);
    const available =
      !!data.value[dataIndex]?.baseCosts[baseCostsIndex]?.available;
    const cost =
      data.value[dataIndex]?.baseCosts[baseCostsIndex]?.cost ||
      data.value[dataIndex]?.baseCosts[baseCostsIndex]?.costPerHour;
    const leadTime = data.value[dataIndex]?.baseCosts[baseCostsIndex]?.leadTime;
    const MOQ = data.value[dataIndex]?.baseCosts[baseCostsIndex]?.MOQ;
    const minCost = data.value[dataIndex]?.baseCosts[baseCostsIndex]?.minCost;
    const ctqDimLeadTime =
      data.value[dataIndex]?.baseCosts[baseCostsIndex]?.ctqDimLeadTime;
    const dimLeadTime =
      data.value[dataIndex]?.baseCosts[baseCostsIndex]?.dimLeadTime;
    const isCostAvailable = cost ? +cost > 0 : false;
    const isLeadTimeAvailable = leadTime ? +leadTime > 0 : false;
    const isDimLeadTimeAvailable = dimLeadTime ? +dimLeadTime > 0 : false;
    const isCTQDimLeadTimeAvailable = ctqDimLeadTime
      ? +ctqDimLeadTime > 0
      : false;
    const isMOQAvailable = MOQ ? +MOQ > 0 : false;
    const isMinCostAvailable = minCost ? +minCost >= 0 : true;

    return {
      available,
      cost,
      leadTime,
      dimLeadTime,
      ctqDimLeadTime,
      MOQ,
      minCost,
      isCostAvailable,
      isLeadTimeAvailable,
      isDimLeadTimeAvailable,
      isCTQDimLeadTimeAvailable,
      isMOQAvailable,
      isMinCostAvailable,
    };
  };

  const onUpdateTable = (
    dataIndex: number,
    baseCostsIndex: number,
    newBaseCost: BaseCostInterface
  ) => {
    if (baseCostsIndex !== -1) {
      data.value[dataIndex].baseCosts[baseCostsIndex] = newBaseCost;
    } else {
      data.value[dataIndex].baseCosts.push(newBaseCost);
    }
  };

  return {
    baseCostValidationError,
    isValidMfrRegionOption,
    getDataIndexes,
    getMfrRegionOptions,
    onUpdateTable,
    valueAsNumber,
    isValidMOQValue,
    isValidMinCostValue,
  };
};
