import { AxiosResponse } from "axios";
import { ref, Ref } from "vue";

export default (
  isEditModeCell: Ref<boolean>,
  startLoader: () => void,
  finishLoader: () => void
) => {
  const onThenActionsTrigger = ref(false);
  const triggeredRegionId = ref("");
  const triggeredRowId = ref("");

  const onUpdateCellFunction = (
    request: Promise<void | AxiosResponse> | void,
    updateFunction?: () => void,
    regionId = "",
    rowId = ""
  ) => {
    if (!request && updateFunction) {
      updateFunction?.();
      onThenActionsTrigger.value = false;
      return;
    }
    startLoader();
    (request as Promise<AxiosResponse>)
      ?.then(() => {
        triggeredRegionId.value = regionId;
        triggeredRowId.value = rowId;
        onThenActionsTrigger.value = true;
        isEditModeCell.value = false;
        updateFunction?.();
      })
      .finally(() => {
        onThenActionsTrigger.value = false;
        finishLoader();
      });
  };

  return {
    onThenActionsTrigger,
    triggeredRegionId,
    triggeredRowId,
    onUpdateCellFunction,
  };
};
