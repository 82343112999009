import { h, Ref } from "vue";
import FocusableTableCell from "@/components/FocusableTableCell.vue";
import SelectableTableCell from "@/components/SelectableTableCell.vue";
import useTableOptions from "@/composables/costing-parameters/useTableOptions";
import useUpdateCellFunction from "@/composables/costing-parameters/useUpdateCellFunction";
import useRenderSorterIcon from "@/composables/costing-parameters/useRenderSorterIcon";
import { AvailabilityValueEnum } from "@/enums/AvailabilityValueEnum";
import { SalesRegionInterface } from "@/types/SalesRegionInterface";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";
import { BaseCostPayloadInterface } from "@/types/costing-parameters/BaseCostPayloadInterface";

export default (
  variables: {
    isEditModeCell: Ref<boolean>;
  },
  functions: BaseCostPayloadInterface
) => {
  const { availabilityOptions } = useTableOptions();

  const {
    onThenActionsTrigger,
    triggeredRegionId,
    triggeredRowId,
    onUpdateCellFunction,
  } = useUpdateCellFunction(
    variables.isEditModeCell,
    functions.startLoader,
    functions.finishLoader
  );
  const { renderSorterIcon } = useRenderSorterIcon();

  const availabilityColumn = (region: SalesRegionInterface) => ({
    title: "Availability",
    key: `availability${region.title}`,
    width: 120,
    sorter: "true",
    renderSorterIcon,
    render: (row: { id: string; baseCosts: BaseCostInterface[] }) => {
      const rowKey = `availability${region.title}`;
      const index = row.baseCosts.findIndex(
        (item) => item.mfrRegionId === region.id
      );
      return h(
        FocusableTableCell,
        {
          id: row.id,
          rowKey,
          ...functions.selectableTableCellProps(),
          class: [
            !functions.isValidMfrRegionOption(row.id, region.id)
              ? ["bordered", "base-cost"]
              : "",
          ],
          onCellClick: () => {
            functions.onFocusReset();
          },
        },
        () => [
          h(SelectableTableCell, {
            value:
              index !== -1 && row.baseCosts[index]?.available
                ? AvailabilityValueEnum.Yes
                : AvailabilityValueEnum.No,
            options: availabilityOptions.value,
            disabled: functions.disableCellValue(row.id, rowKey),
            onThenActionsTrigger:
              onThenActionsTrigger.value &&
              triggeredRegionId.value === region.id &&
              triggeredRowId.value === row.id,
            updateFunction: (newAvailable: AvailabilityValueEnum) => {
              onUpdateCellFunction(
                functions.onUpdateAvailability(newAvailable, row, region),
                () =>
                  functions.onUpdateAvailabilityValue(
                    newAvailable,
                    row,
                    region
                  ),
                region.id,
                row.id
              );
            },
            updateMessage: `${region.title} availability is successfully updated`,
            onEditMode: (isEdit: boolean) => {
              variables.isEditModeCell.value = isEdit;
            },
            onClick: () => {
              functions.onSetActiveCell(row.id, rowKey);
            },
            onKeydownEsc: () => functions.onKeydownEsc(row.id),
            onKeydownTab: (e: { shiftKey: boolean }) =>
              functions.onKeydownTab(e, row.id, rowKey),
          }),
        ]
      );
    },
  });

  return { availabilityColumn };
};
