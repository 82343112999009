import { computed, onMounted, onUpdated, ref } from "vue";

export default () => {
  const headerHeight = ref(0);
  const containerHeightOffset = computed(() => {
    return {
      "--container-height-offset": `${headerHeight.value}px`,
    };
  });

  const partDetailsHeaderHeight = ref(0);
  const partDetailsHeaderHeightOffset = computed(() => {
    return {
      "--part-details-header-height-offset": `${partDetailsHeaderHeight.value}px`,
    };
  });

  const costingParametersHeight = ref(0);
  const costingParametersHeightOffset = computed(() => {
    return {
      "--costing-parameters-height-offset": `${costingParametersHeight.value}px`,
    };
  });

  const costingParametersHeaderHeight = ref(0);
  const costingParametersHeaderHeightOffset = computed(() => {
    return {
      "--costing-parameters-header-height-offset": `${costingParametersHeaderHeight.value}px`,
    };
  });

  const costingParametersOptionsHeight = ref(0);
  const costingParametersOptionsHeightOffset = computed(() => {
    return {
      "--costing-parameter-options-height-offset": `${costingParametersOptionsHeight.value}px`,
    };
  });
  const costingParametersPaginationHeight = ref(0);
  const costingParametersPaginationHeightOffset = computed(() => {
    return {
      "--costing-parameters-pagination-height-offset": `${costingParametersPaginationHeight.value}px`,
    };
  });

  onMounted(() => {
    const header = document.getElementById("the-header");
    headerHeight.value = header ? header.clientHeight : 0;
    setHeight();
    setCostingParametersHeight();
    setCostingParametersHeaderHeight();
    setCostingParametersOptionsHeight();
    setCostingParametersPaginationHeight();
  });

  onUpdated(() => {
    if (!partDetailsHeaderHeight.value) {
      setHeight();
    }
    if (!costingParametersHeight.value) {
      setCostingParametersHeight();
    }
    if (!costingParametersHeaderHeight.value) {
      setCostingParametersHeaderHeight();
    }
    if (!costingParametersOptionsHeight.value) {
      setCostingParametersOptionsHeight();
    }
    if (!costingParametersPaginationHeight.value) {
      setCostingParametersPaginationHeight();
    }
  });

  const setHeight = () => {
    const partDetailsHeader = document.getElementById("part-details-header");
    partDetailsHeaderHeight.value = partDetailsHeader
      ? partDetailsHeader.clientHeight + 1
      : 0;
  };
  const setCostingParametersHeight = () => {
    const costingParameters = document.getElementById("costing-parameters");
    costingParametersHeight.value = costingParameters
      ? costingParameters.clientHeight + 1
      : 0;
  };

  const setCostingParametersHeaderHeight = () => {
    const costingParametersHeader = document.getElementById(
      "costing-parameters-header"
    );
    costingParametersHeaderHeight.value = costingParametersHeader
      ? costingParametersHeader.clientHeight + 1
      : 0;
  };
  const setCostingParametersOptionsHeight = () => {
    const costingParametersOptions = document.getElementById(
      "costing-parameter-options"
    );
    costingParametersOptionsHeight.value = costingParametersOptions
      ? costingParametersOptions.clientHeight + 1
      : 0;
  };
  const setCostingParametersPaginationHeight = () => {
    const costingParametersPagination = document.getElementById(
      "costing-parameters-pagination"
    );
    costingParametersPaginationHeight.value = costingParametersPagination
      ? costingParametersPagination.clientHeight + 1
      : 0;
  };

  return {
    headerHeight,
    containerHeightOffset,
    costingParametersHeight,
    costingParametersHeaderHeight,
    costingParametersOptionsHeight,
    costingParametersPaginationHeight,
    partDetailsHeaderHeight,
    partDetailsHeaderHeightOffset,
    costingParametersHeightOffset,
    costingParametersHeaderHeightOffset,
    costingParametersOptionsHeightOffset,
    costingParametersPaginationHeightOffset,
  };
};
