import { h, Ref, ref } from "vue";
import FocusableTableCell from "@/components/FocusableTableCell.vue";
import EditableTableCell from "@/components/EditableTableCell.vue";
import useUpdateCellFunction from "@/composables/costing-parameters/useUpdateCellFunction";
import useRenderSorterIcon from "@/composables/costing-parameters/useRenderSorterIcon";
import { SalesRegionInterface } from "@/types/SalesRegionInterface";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";
import { BaseCostPayloadInterface } from "@/types/costing-parameters/BaseCostPayloadInterface";

export default (
  variables: {
    leadTimeRef: Ref<HTMLElement | undefined>;
    isEditModeCell: Ref<boolean>;
    leadTimeTitle: string;
    showCTQLeadTimeColumn: boolean;
  },
  functions: BaseCostPayloadInterface
) => {
  const displayDefaultMessage = ref(false);

  const {
    onThenActionsTrigger,
    triggeredRegionId,
    triggeredRowId,
    onUpdateCellFunction,
  } = useUpdateCellFunction(
    variables.isEditModeCell,
    functions.startLoader,
    functions.finishLoader
  );

  const { renderSorterIcon } = useRenderSorterIcon();

  const leadTimeColumn = (region: SalesRegionInterface) => ({
    title: variables.leadTimeTitle,
    key: `${variables.showCTQLeadTimeColumn ? "dimLeadTime" : "leadTime"}${
      region.title
    }`,
    width: 120,
    sorter: "true",
    renderSorterIcon,
    render: (row: { id: string; baseCosts: BaseCostInterface[] }) => {
      const rowKey = `${
        variables.showCTQLeadTimeColumn ? "dimLeadTime" : "leadTime"
      }${region.title}`;
      const { leadTime, dimLeadTime, available } =
        functions.getMfrRegionOptions(row.id, region.id);

      const getLeadTime = () => {
        if (leadTime && leadTime !== "0") {
          return `${leadTime}`;
        }
        if (dimLeadTime && dimLeadTime !== "0") {
          return `${dimLeadTime}`;
        }
        return "";
      };

      return h(
        FocusableTableCell,
        {
          id: row.id,
          class: [
            !functions.isValidMfrRegionOption(row.id, region.id)
              ? ["bordered", "base-cost", "editable-table-cell"]
              : "",
          ],
          ...functions.editableTableCellProps(),
          onCellClick: () => {
            functions.onFocusReset();
          },
        },
        () => [
          h(EditableTableCell, {
            ref: !functions.isValidMfrRegionOption(row.id, region.id)
              ? variables.leadTimeRef
              : undefined,
            value: getLeadTime(),
            withSavingLogic: false,
            showEditButton: false,
            showSaveButton: false,
            triggerOnBlur: true,
            showPlaceholder: !functions.isValidMfrRegionOption(
              row.id,
              region.id
            ),
            disabled: functions.disableCellValue(row.id, rowKey) || !available,
            isUpdateAfterSendingRequest: false,
            onThenActionsTrigger:
              onThenActionsTrigger.value &&
              triggeredRegionId.value === region.id &&
              triggeredRowId.value === row.id,
            validateAndGetPayload: (inputValue: string) =>
              functions.validateAndGetNumberPayload(
                inputValue,
                variables.showCTQLeadTimeColumn ? "Dim. Lead time" : "Lead time"
              ),
            updateFunction: (newLeadTime: string) => {
              displayDefaultMessage.value = !functions.isValidMfrRegionOption(
                row.id,
                region.id
              );
              onUpdateCellFunction(
                functions.onUpdateLeadTime?.(newLeadTime, row, region),
                () => {
                  if (newLeadTime !== "") {
                    functions.onUpdateLeadTimeValue?.(newLeadTime, row, region);
                  }
                },
                region.id,
                row.id
              );
            },
            updateMessage: `${region.title} ${
              displayDefaultMessage.value
                ? "availability"
                : variables.showCTQLeadTimeColumn
                ? "Dim. Lead time"
                : "Lead time"
            } is successfully updated`,
            onClick: () => {
              functions.onSetActiveCell(row.id, rowKey);
              functions.isValidMfrRegionOption(row.id, region.id);
            },
            onEditMode: (isEdit: boolean) => {
              variables.isEditModeCell.value = isEdit;
            },
          }),
        ]
      );
    },
  });
  return { leadTimeColumn };
};
