import { Ref, nextTick } from "vue";
import { AxiosResponse } from "axios";
import useBaseCostMethods from "@/composables/costing-parameters/useBaseCostMethods";
import { SalesRegionInterface } from "@/types/SalesRegionInterface";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";
import AvailabilityInterface from "@/types/admin-parameters/costing-parameters/AvailabilityInterface";

export default (
  data: Ref<AvailabilityInterface[]>,
  keyField: string,
  showColumns: {
    showLeadTimeColumn: boolean;
    showCTQLeadTimeColumn: boolean;
    showMOQColumn: boolean;
    showMinCostColumn: boolean;
  },
  addRequest: (payload: BaseCostInterface[]) => Promise<AxiosResponse>,
  changeRequest: (payload: BaseCostInterface) => Promise<AxiosResponse>,
  onClickCostCell: () => void,
  onClickLeadTimeCell: () => void
) => {
  const {
    isValidMfrRegionOption,
    getDataIndexes,
    getMfrRegionOptions,
    onUpdateTable,
    valueAsNumber,
  } = useBaseCostMethods(data, showColumns);

  const areAllFieldsFilledIn = (
    available: boolean,
    isDimLeadTimeAvailable: boolean,
    isCostAvailable: boolean,
    newLeadTime: string
  ) => {
    return (
      available &&
      isCostAvailable &&
      isDimLeadTimeAvailable &&
      valueAsNumber(newLeadTime) > 0
    );
  };

  const allowedToAddNewBaseCost = (
    rowId: string,
    regionId: string,
    newLeadTime: string,
    baseCostId?: string
  ) => {
    const { available, isDimLeadTimeAvailable, isCostAvailable } =
      getMfrRegionOptions(rowId, regionId);
    return (
      !baseCostId &&
      areAllFieldsFilledIn(
        available,
        isDimLeadTimeAvailable,
        isCostAvailable,
        newLeadTime
      )
    );
  };

  const allowedToChangeExistingBaseCost = (
    rowId: string,
    regionId: string,
    newLeadTime: string,
    baseCostId?: string
  ) => {
    const { available, isDimLeadTimeAvailable, isCostAvailable } =
      getMfrRegionOptions(rowId, regionId);
    return (
      baseCostId &&
      areAllFieldsFilledIn(
        available,
        isDimLeadTimeAvailable,
        isCostAvailable,
        newLeadTime
      )
    );
  };

  const moveToNextCell = (rowId: string, regionId: string) => {
    const { cost, dimLeadTime } = getMfrRegionOptions(rowId, regionId);
    if (isValidMfrRegionOption(rowId, regionId)) {
      return;
    }
    if (valueAsNumber(cost) === 0) {
      nextTick(() => {
        onClickCostCell();
      });
    } else if (valueAsNumber(dimLeadTime) === 0) {
      nextTick(() => {
        onClickLeadTimeCell();
      });
    }
  };

  const onUpdateCTQDimLeadTime = (
    newLeadTime: string,
    row: AvailabilityInterface,
    region: SalesRegionInterface
  ): Promise<void> | void => {
    const index = row.baseCosts.findIndex(
      (item) => item.mfrRegionId === region.id
    );
    const baseCostId = row.baseCosts[index]?.id;
    const { cost, dimLeadTime } = getMfrRegionOptions(row.id, region.id);
    const { dataIndex, baseCostsIndex } = getDataIndexes(row.id, region.id);

    if (allowedToAddNewBaseCost(row.id, region.id, newLeadTime, baseCostId)) {
      return addRequest([
        {
          [keyField]: row.id,
          available: true,
          costPerHour: cost,
          dimLeadTime: `${dimLeadTime}`,
          ctqDimLeadTime: `${valueAsNumber(newLeadTime)}`,
          mfrRegionId: region.id,
        },
      ]).then((res) => {
        onUpdateTable(dataIndex, baseCostsIndex, {
          ...res.data.data[0],
          mfrRegionId: region.id,
        });
      });
    }
    if (
      allowedToChangeExistingBaseCost(
        row.id,
        region.id,
        newLeadTime,
        baseCostId
      )
    ) {
      return changeRequest({
        [keyField]: row.id,
        id: row.baseCosts[index].id,
        available: true,
        costPerHour: cost,
        dimLeadTime: `${dimLeadTime}`,
        ctqDimLeadTime: `${valueAsNumber(newLeadTime)}`,
      }).then((res) => {
        onUpdateTable(dataIndex, baseCostsIndex, {
          ...res.data.data,
          mfrRegionId: region.id,
        });
      });
    }
  };

  const onUpdateCTQDimLeadTimeValue = (
    newVal: string,
    row: AvailabilityInterface,
    region: SalesRegionInterface
  ) => {
    const { dataIndex, baseCostsIndex } = getDataIndexes(row.id, region.id);

    if (showColumns.showCTQLeadTimeColumn) {
      data.value[dataIndex].baseCosts[
        baseCostsIndex
      ].ctqDimLeadTime = `${valueAsNumber(newVal)}`;
    } else {
      data.value[dataIndex].baseCosts.push({
        costPerHour:
          data.value[dataIndex].baseCosts[baseCostsIndex]?.costPerHour || "",
        dimLeadTime:
          data.value[dataIndex].baseCosts[baseCostsIndex]?.dimLeadTime || "",
        ctqDimLeadTime: `${valueAsNumber(newVal)}`,
        mfrRegionId: region.id,
      });
    }
    moveToNextCell(row.id, region.id);
  };

  return {
    onUpdateCTQDimLeadTime,
    onUpdateCTQDimLeadTimeValue,
  };
};
