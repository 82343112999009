import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-394a8576"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "costing-parameter-options",
  id: "costing-parameter-options"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.showSearch)
      ? (_openBlock(), _createBlock($setup["SearchInput"], {
          key: 0,
          modelValue: new Set($setup.searchValue),
          placeholder: "Search",
          "onUpdate:modelValue": $setup.onSearch
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    ($props.showAddLine)
      ? (_openBlock(), _createBlock($setup["DotsMenu"], {
          key: 1,
          options: $setup.plusOptions,
          trigger: "click",
          placement: "top",
          size: 34,
          onSelectOption: $setup.onSelectPlusOption
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Plus"])
          ]),
          _: 1
        }, 8, ["options"]))
      : _createCommentVNode("", true),
    ($props.showMenu)
      ? (_openBlock(), _createBlock($setup["DotsMenu"], {
          key: 2,
          options: $setup.menuOptions,
          size: 34,
          horizontal: "",
          onSelectOption: $setup.onSelectMenuOption
        }, null, 8, ["options"]))
      : _createCommentVNode("", true)
  ]))
}