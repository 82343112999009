import { Ref, nextTick } from "vue";
import { AxiosResponse } from "axios";
import useBaseCostMethods from "@/composables/costing-parameters/useBaseCostMethods";
import { AvailabilityValueEnum } from "@/enums/AvailabilityValueEnum";
import { SalesRegionInterface } from "@/types/SalesRegionInterface";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";
import AvailabilityInterface from "@/types/admin-parameters/costing-parameters/AvailabilityInterface";
import { ExtraColumnKey } from "@/enums/costing-parameters/ExtraColumnKey";

export default (
  data: Ref<AvailabilityInterface[]>,
  keyField: string,
  showColumns: {
    showLeadTimeColumn: boolean;
    showCTQLeadTimeColumn: boolean;
    showMOQColumn: boolean;
    showMinCostColumn: boolean;
  },
  addRequest: (payload: BaseCostInterface[]) => Promise<AxiosResponse>,
  changeRequest: (payload: BaseCostInterface) => Promise<AxiosResponse>,
  onClickCostCell: () => void,
  onClickMOQCell?: () => void
) => {
  const {
    baseCostValidationError,
    isValidMfrRegionOption,
    getDataIndexes,
    getMfrRegionOptions,
    onUpdateTable,
  } = useBaseCostMethods(data, showColumns);

  const areAllFieldsFilledIn = (
    newAvailable: AvailabilityValueEnum,
    isCostAvailable: boolean,
    isLeadTimeAvailable: boolean,
    isMOQAvailable: boolean,
    isMinCostAvailable: boolean
  ) => {
    const validLeadTime =
      isLeadTimeAvailable || !showColumns.showLeadTimeColumn;
    const validMOQ = isMOQAvailable || !showColumns.showMOQColumn;
    const validMinCost = isMinCostAvailable || !showColumns.showMinCostColumn;
    const validNumberFields = validLeadTime && validMOQ && validMinCost;
    return (
      newAvailable === AvailabilityValueEnum.Yes &&
      isCostAvailable &&
      validNumberFields
    );
  };

  const allowedToAddNewBaseCost = (
    rowId: string,
    regionId: string,
    newAvailable: AvailabilityValueEnum,
    id?: string
  ) => {
    const {
      isCostAvailable,
      isLeadTimeAvailable,
      isMOQAvailable,
      isMinCostAvailable,
    } = getMfrRegionOptions(rowId, regionId);
    return (
      !id &&
      areAllFieldsFilledIn(
        newAvailable,
        isCostAvailable,
        isLeadTimeAvailable,
        isMOQAvailable,
        isMinCostAvailable
      )
    );
  };

  const allowedToChangeExistingBaseCost = (
    rowId: string,
    regionId: string,
    newAvailable: AvailabilityValueEnum,
    id?: string
  ) => {
    const {
      isCostAvailable,
      isLeadTimeAvailable,
      isMOQAvailable,
      isMinCostAvailable,
    } = getMfrRegionOptions(rowId, regionId);
    return (
      id &&
      areAllFieldsFilledIn(
        newAvailable,
        isCostAvailable,
        isLeadTimeAvailable,
        isMOQAvailable,
        isMinCostAvailable
      )
    );
  };

  const allowedToSetAsNoExitingBaseCost = (
    newAvailable: AvailabilityValueEnum,
    id?: string
  ) => {
    return id && newAvailable === AvailabilityValueEnum.No;
  };

  const allowedToSetAsNoNewBaseCost = (
    newAvailable: AvailabilityValueEnum,
    availableField = false,
    id?: string
  ) => {
    return !id && availableField && newAvailable === AvailabilityValueEnum.No;
  };

  const getLeadTimeValue = (value: string) => {
    return showColumns.showLeadTimeColumn ? value : undefined;
  };

  const getAddRequestPayload = (rowId: string, regionId: string) => {
    const { cost, leadTime, dimLeadTime, MOQ, minCost } = getMfrRegionOptions(
      rowId,
      regionId
    );
    return keyField === "qualityControlProcessId"
      ? [
          {
            [keyField]: rowId,
            available: true,
            costPerHour: `${cost}`,
            ctqDimLeadTime: `${leadTime}`,
            dimLeadTime: `${dimLeadTime}`,
            mfrRegionId: regionId,
          },
        ]
      : [
          {
            [keyField]: rowId,
            available: true,
            cost: `${cost}`,
            leadTime: getLeadTimeValue(`${leadTime}`),
            mfrRegionId: regionId,
            ...(showColumns.showMOQColumn && { MOQ }),
            ...(showColumns.showMinCostColumn && { minCost: `${minCost}` }),
          },
        ];
  };

  const getQualityControlPayload = (
    rowId: string,
    regionId: string,
    available: boolean,
    id?: string
  ) => {
    const { cost, leadTime, dimLeadTime } = getMfrRegionOptions(
      rowId,
      regionId
    );
    return {
      id,
      [keyField]: rowId,
      available,
      costPerHour: `${available ? cost : 0}`,
      ctqDimLeadTime: `${available ? leadTime : 0}`,
      dimLeadTime: `${available ? dimLeadTime : 0}`,
    };
  };

  const getOtherTablesPayload = (
    rowId: string,
    regionId: string,
    available: boolean,
    id?: string
  ) => {
    const { cost, leadTime, MOQ, minCost } = getMfrRegionOptions(
      rowId,
      regionId
    );
    return {
      id,
      [keyField]: rowId,
      available,
      cost: `${available ? cost : 0}`,
      leadTime: getLeadTimeValue(`${available ? leadTime : 0}`),
      ...(showColumns.showMOQColumn && { MOQ: available ? MOQ : 0 }),
      ...(showColumns.showMinCostColumn && {
        minCost: available ? `${minCost || 0}` : "0",
      }),
    };
  };
  const getChangeRequestPayload = (
    rowId: string,
    regionId: string,
    available: boolean,
    id?: string
  ) => {
    return keyField === "qualityControlProcessId"
      ? getQualityControlPayload(rowId, regionId, available, id)
      : getOtherTablesPayload(rowId, regionId, available, id);
  };

  const onUpdateAvailability = (
    newAvailable: AvailabilityValueEnum,
    row: AvailabilityInterface,
    region: SalesRegionInterface
  ): Promise<void> | void => {
    const index = row.baseCosts.findIndex(
      (item) => item.mfrRegionId === region.id
    );
    const baseCostId = row.baseCosts[index]?.id;
    const { dataIndex, baseCostsIndex } = getDataIndexes(row.id, region.id);

    if (allowedToAddNewBaseCost(row.id, region.id, newAvailable, baseCostId)) {
      return addRequest(getAddRequestPayload(row.id, region.id)).then((res) => {
        onUpdateTable(dataIndex, baseCostsIndex, {
          ...(res.data.data || res.data),
          mfrRegionId: region.id,
        });
      });
    }
    if (
      allowedToChangeExistingBaseCost(
        row.id,
        region.id,
        newAvailable,
        baseCostId
      )
    ) {
      return changeRequest(
        getChangeRequestPayload(
          row.id,
          region.id,
          true,
          row.baseCosts[index].id
        )
      ).then((res) => {
        onUpdateTable(dataIndex, baseCostsIndex, {
          ...(res.data.data || res.data),
          mfrRegionId: region.id,
        });
      });
    }
    if (allowedToSetAsNoExitingBaseCost(newAvailable, baseCostId)) {
      return changeRequest(
        getChangeRequestPayload(
          row.id,
          region.id,
          false,
          row.baseCosts[index].id
        )
      ).then((res) => {
        onUpdateTable(dataIndex, baseCostsIndex, {
          ...(res.data.data || res.data),
          mfrRegionId: region.id,
        });
      });
    }
    if (
      allowedToSetAsNoNewBaseCost(
        newAvailable,
        row.baseCosts[index]?.available,
        baseCostId
      )
    ) {
      row.baseCosts[index].available = false;
      baseCostValidationError.value = false;
      if (showColumns.showMOQColumn) {
        row.baseCosts[index].MOQ = null;
      }
      if (showColumns.showMinCostColumn) {
        row.baseCosts[index].minCost = null;
      }
      if (keyField === "qualityControlProcessId") {
        row.baseCosts[index].costPerHour = "";
        row.baseCosts[index].dimLeadTime = "";
        row.baseCosts[index].ctqDimLeadTime = "";
      } else {
        row.baseCosts[index].cost = "";
        row.baseCosts[index].leadTime = "";
      }
    }
  };

  const setEmptyValues = (dataIndex: number, baseCostsIndex: number) => {
    data.value[dataIndex].baseCosts[baseCostsIndex].cost = "";
    if (showColumns.showLeadTimeColumn) {
      data.value[dataIndex].baseCosts[baseCostsIndex].leadTime = "";
    }
    if (showColumns.showMOQColumn) {
      data.value[dataIndex].baseCosts[baseCostsIndex].MOQ = null;
    }
    if (showColumns.showMinCostColumn) {
      data.value[dataIndex].baseCosts[baseCostsIndex].minCost = null;
    }
  };

  const moveToTheNextStep = () => {
    nextTick(() => {
      if (showColumns.showMOQColumn && onClickMOQCell) {
        onClickMOQCell();
      } else {
        onClickCostCell();
      }
    });
  };

  const onUpdateAvailabilityValue = (
    newVal: AvailabilityValueEnum,
    row: AvailabilityInterface,
    region: SalesRegionInterface
  ) => {
    const { dataIndex, baseCostsIndex } = getDataIndexes(row.id, region.id);
    if (baseCostsIndex !== -1) {
      data.value[dataIndex].baseCosts[baseCostsIndex].available =
        newVal === AvailabilityValueEnum.Yes;
      if (newVal === AvailabilityValueEnum.No) {
        setEmptyValues(dataIndex, baseCostsIndex);
      }
    } else {
      const { cost, leadTime, MOQ, minCost } = getMfrRegionOptions(
        row.id,
        region.id
      );
      data.value[dataIndex].baseCosts.push({
        available: newVal === AvailabilityValueEnum.Yes,
        mfrRegionId: region.id,
        cost: `${cost || ""}`,
        leadTime: getLeadTimeValue(`${leadTime || ""}`),
        ...(showColumns.showMOQColumn && { MOQ: MOQ || null }),
        ...(showColumns.showMinCostColumn && {
          minCost: `${minCost || 0}`,
        }),
      });
    }
    if (!isValidMfrRegionOption(row.id, region.id)) {
      moveToTheNextStep();
    }
  };

  const onClickOutsideBaseCostPopover = (
    e: MouseEvent,
    row: AvailabilityInterface,
    region: SalesRegionInterface,
    disableTable: Ref<boolean>
  ) => {
    if (disableTable.value) {
      return;
    }
    const indexOfCellWithValidation = Array.from(
      (e.target as HTMLElement).offsetParent?.children as HTMLCollection
    ).findIndex(
      (item) =>
        Array.from(item.classList).includes("base-cost") &&
        Array.from(item.classList).includes("bordered")
    );
    if (indexOfCellWithValidation === -1) {
      onUpdateAvailability(AvailabilityValueEnum.No, row, region);
      baseCostValidationError.value = false;
    }
  };

  return {
    baseCostValidationError,
    onUpdateAvailability,
    onUpdateAvailabilityValue,
    onClickOutsideBaseCostPopover,
  };
};
