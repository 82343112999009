import { Ref, nextTick } from "vue";
import { AxiosResponse } from "axios";
import useBaseCostMethods from "@/composables/costing-parameters/useBaseCostMethods";
import { SalesRegionInterface } from "@/types/SalesRegionInterface";
import {
  BaseCostCellType,
  BaseCostInterface,
} from "@/types/costing-parameters/BaseCostInterface";
import AvailabilityInterface from "@/types/admin-parameters/costing-parameters/AvailabilityInterface";

export default (
  data: Ref<AvailabilityInterface[]>,
  keyField: string,
  showColumns: {
    showLeadTimeColumn: boolean;
    showCTQLeadTimeColumn: boolean;
    showMOQColumn: boolean;
    showMinCostColumn: boolean;
  },
  addRequest: (payload: BaseCostInterface[]) => Promise<AxiosResponse>,
  changeRequest: (payload: BaseCostInterface) => Promise<AxiosResponse>,
  onClickLeadTimeCell: () => void,
  onClickMinCostCell?: () => void,
  onClickCTQDimLeadTimeCell?: () => void,
  onClickMOQCell?: () => void
) => {
  const {
    isValidMfrRegionOption,
    getDataIndexes,
    getMfrRegionOptions,
    onUpdateTable,
    valueAsNumber,
    isValidMOQValue,
    isValidMinCostValue,
  } = useBaseCostMethods(data, showColumns);

  const getLeadTimeValue = (value?: string | null) => {
    return showColumns.showLeadTimeColumn ? value : undefined;
  };
  const isValidLeadTimeValue = (
    leadTime?: BaseCostCellType,
    dimLeadTime?: BaseCostCellType
  ) => {
    return (
      (showColumns.showLeadTimeColumn && valueAsNumber(leadTime) > 0) ||
      (showColumns.showCTQLeadTimeColumn && valueAsNumber(dimLeadTime) > 0)
    );
  };

  const isValidLeadTime = (
    leadTimeAsNumber: number,
    dimLeadTimeAsNumber: number,
    ctqDimLeadTimeAsNumber: number
  ) =>
    leadTimeAsNumber > 0 ||
    (dimLeadTimeAsNumber > 0 && ctqDimLeadTimeAsNumber > 0) ||
    !showColumns.showLeadTimeColumn;

  const areAdditionalFieldsValid = (
    MOQ: number | null | undefined,
    minCost: BaseCostCellType | undefined
  ) => isValidMOQValue(MOQ) && isValidMinCostValue(minCost);

  const allowedToAddNewBaseCost = (
    rowId: string,
    regionId: string,
    id?: string
  ) => {
    const { available, leadTime, MOQ, dimLeadTime, ctqDimLeadTime, minCost } =
      getMfrRegionOptions(rowId, regionId);

    return (
      available &&
      !id &&
      isValidLeadTime(
        valueAsNumber(leadTime),
        valueAsNumber(dimLeadTime),
        valueAsNumber(ctqDimLeadTime)
      ) &&
      areAdditionalFieldsValid(MOQ, minCost)
    );
  };

  const allowedToChangeExistingBaseCost = (
    rowId: string,
    regionId: string,
    id?: string
  ) => {
    const { available, leadTime, MOQ, dimLeadTime, ctqDimLeadTime, minCost } =
      getMfrRegionOptions(rowId, regionId);

    return (
      id &&
      available &&
      isValidLeadTime(
        valueAsNumber(leadTime),
        valueAsNumber(dimLeadTime),
        valueAsNumber(ctqDimLeadTime)
      ) &&
      areAdditionalFieldsValid(MOQ, minCost)
    );
  };

  const getAddRequestPayload = (
    rowId: string,
    regionId: string,
    newCost: string
  ) => {
    const { leadTime, MOQ, dimLeadTime, ctqDimLeadTime, minCost } =
      getMfrRegionOptions(rowId, regionId);

    return [
      keyField === "qualityControlProcessId"
        ? {
            [keyField]: rowId,
            available: true,
            costPerHour: `${valueAsNumber(newCost)}`,
            dimLeadTime: `${dimLeadTime}`,
            ctqDimLeadTime: `${ctqDimLeadTime}`,
            mfrRegionId: regionId,
          }
        : {
            [keyField]: rowId,
            available: true,
            cost: `${valueAsNumber(newCost)}`,
            leadTime: getLeadTimeValue(leadTime),
            mfrRegionId: regionId,
            ...(showColumns.showMOQColumn ? { MOQ: valueAsNumber(MOQ) } : {}),
            ...(showColumns.showMinCostColumn
              ? { minCost: `${minCost || 0}` }
              : {}),
          },
    ];
  };

  const getChangeRequestPayload = (
    rowId: string,
    regionId: string,
    newCost: string,
    id?: string
  ) => {
    const { leadTime, MOQ, dimLeadTime, ctqDimLeadTime, minCost } =
      getMfrRegionOptions(rowId, regionId);

    return keyField === "qualityControlProcessId"
      ? {
          [keyField]: rowId,
          id,
          available: true,
          costPerHour: `${valueAsNumber(newCost)}`,
          dimLeadTime: `${dimLeadTime}`,
          ctqDimLeadTime: `${ctqDimLeadTime}`,
        }
      : {
          [keyField]: rowId,
          id,
          available: true,
          cost: `${valueAsNumber(newCost)}`,
          leadTime: getLeadTimeValue(`${leadTime}`),
          ...(showColumns.showMOQColumn ? { MOQ: valueAsNumber(MOQ) } : {}),
          ...(showColumns.showMinCostColumn
            ? { minCost: `${minCost || 0}` }
            : {}),
        };
  };

  const moveToNextCell = (rowId: string, regionId: string) => {
    const { leadTime, MOQ, dimLeadTime, ctqDimLeadTime, minCost } =
      getMfrRegionOptions(rowId, regionId);

    if (isValidMfrRegionOption(rowId, regionId)) {
      return;
    }
    if (!isValidMOQValue(MOQ) && onClickMOQCell) {
      nextTick(() => {
        onClickMOQCell();
      });
    } else if (onClickMinCostCell) {
      nextTick(() => {
        onClickMinCostCell();
      });
    } else if (!isValidLeadTimeValue(leadTime, dimLeadTime)) {
      nextTick(() => {
        onClickLeadTimeCell();
      });
    } else if (
      showColumns.showCTQLeadTimeColumn &&
      valueAsNumber(ctqDimLeadTime) === 0 &&
      onClickCTQDimLeadTimeCell
    ) {
      nextTick(() => {
        onClickCTQDimLeadTimeCell();
      });
    }
  };

  const onUpdateCost = (
    newCost: string,
    row: AvailabilityInterface,
    region: SalesRegionInterface
  ): Promise<void> | void => {
    const index = row.baseCosts.findIndex(
      (item) => item.mfrRegionId === region.id
    );
    const baseCostId = row.baseCosts[index]?.id;
    const { dataIndex, baseCostsIndex } = getDataIndexes(row.id, region.id);

    if (allowedToAddNewBaseCost(row.id, region.id, baseCostId)) {
      return addRequest(getAddRequestPayload(row.id, region.id, newCost)).then(
        (res) => {
          onUpdateTable(dataIndex, baseCostsIndex, {
            ...res.data.data[0],
            mfrRegionId: region.id,
          });
        }
      );
    }
    if (allowedToChangeExistingBaseCost(row.id, region.id, baseCostId)) {
      return changeRequest(
        getChangeRequestPayload(row.id, region.id, newCost, baseCostId)
      ).then((res) => {
        onUpdateTable(dataIndex, baseCostsIndex, {
          ...(res.data.data || res.data),
          mfrRegionId: region.id,
        });
      });
    }
  };

  const onUpdateCostValue = (
    newVal: string,
    row: AvailabilityInterface,
    region: SalesRegionInterface
  ) => {
    const { MOQ, minCost } = getMfrRegionOptions(row.id, region.id);
    const { dataIndex, baseCostsIndex } = getDataIndexes(row.id, region.id);

    if (baseCostsIndex !== -1) {
      if (showColumns.showCTQLeadTimeColumn) {
        data.value[dataIndex].baseCosts[
          baseCostsIndex
        ].costPerHour = `${valueAsNumber(newVal)}`;
      } else {
        data.value[dataIndex].baseCosts[baseCostsIndex].cost = `${valueAsNumber(
          newVal
        )}`;
      }
    } else {
      data.value[dataIndex].baseCosts.push(
        showColumns.showCTQLeadTimeColumn
          ? {
              costPerHour: `${valueAsNumber(newVal)}`,
              dimLeadTime:
                data.value[dataIndex].baseCosts[baseCostsIndex]?.dimLeadTime ||
                "",
              ctqDimLeadTime:
                data.value[dataIndex].baseCosts[baseCostsIndex]
                  ?.ctqDimLeadTime || "",
              mfrRegionId: region.id,
            }
          : {
              cost: `${valueAsNumber(newVal)}`,
              leadTime: getLeadTimeValue(
                data.value[dataIndex].baseCosts[baseCostsIndex]?.leadTime || ""
              ),
              mfrRegionId: region.id,
              ...(showColumns.showMOQColumn ? { MOQ: valueAsNumber(MOQ) } : {}),
              ...(showColumns.showMinCostColumn
                ? { minCost: `${minCost || 0}` }
                : {}),
            }
      );
    }
    moveToNextCell(row.id, region.id);
  };

  return {
    onUpdateCost,
    onUpdateCostValue,
  };
};
