import { h, Ref, ref } from "vue";
import { NPopover } from "naive-ui";
import FocusableTableCell from "@/components/FocusableTableCell.vue";
import EditableTableCell from "@/components/EditableTableCell.vue";
import {
  minValueMessage,
  moreThanMessage,
} from "@/composables/validationMessages";
import useUpdateCellFunction from "@/composables/costing-parameters/useUpdateCellFunction";
import useRenderSorterIcon from "@/composables/costing-parameters/useRenderSorterIcon";
import { SalesRegionInterface } from "@/types/SalesRegionInterface";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";
import { BaseCostPayloadInterface } from "@/types/costing-parameters/BaseCostPayloadInterface";

export default (
  variables: {
    costRef: Ref<HTMLElement | undefined>;
    costTitle: string;
    activeCellKey: Ref<string>;
    activeCellId: Ref<string>;
    numberFieldError: Ref<boolean>;
    validationFieldName: Ref<string>;
    isEditModeCell: Ref<boolean>;
    disableTable: Ref<boolean>;
    showLeadTimeColumn: boolean;
    showMOQColumn: boolean;
    showCTQLeadTimeColumn: boolean;
    showMinCostColumn: boolean;
  },
  functions: BaseCostPayloadInterface
): Record<string, any> => {
  const displayDefaultMessage = ref(false);

  const {
    onThenActionsTrigger,
    triggeredRegionId,
    triggeredRowId,
    onUpdateCellFunction,
  } = useUpdateCellFunction(
    variables.isEditModeCell,
    functions.startLoader,
    functions.finishLoader
  );

  const { renderSorterIcon } = useRenderSorterIcon();

  const getRequiredFieldMessage = () =>
    `You must enter ${variables.showMOQColumn ? "MOQ," : ""} cost${
      variables.showMinCostColumn ? ", min. Unit Cost," : ""
    } ${variables.showLeadTimeColumn ? " and lead time" : ""}`;

  const getValidationMessage = () => {
    if (
      variables.numberFieldError.value &&
      variables.activeCellKey.value.includes("minCost")
    ) {
      return minValueMessage(variables.validationFieldName.value, 0);
    } else if (variables.numberFieldError.value) {
      return moreThanMessage(variables.validationFieldName.value, 0);
    } else {
      return getRequiredFieldMessage();
    }
  };
  const costColumn = (region: SalesRegionInterface) => ({
    title: variables.costTitle,
    key: `cost${region.title}`,
    width: 120,
    sorter: "true",
    renderSorterIcon,
    render: (row: { id: string; baseCosts: BaseCostInterface[] }) => {
      const rowKey = `cost${region.title}`;
      const { cost, available } = functions.getMfrRegionOptions(
        row.id,
        region.id
      );
      const isBaseCostRowKey =
        variables.activeCellKey.value === `moq${region.title}` ||
        variables.activeCellKey.value === `minCost${region.title}` ||
        variables.activeCellKey.value === rowKey ||
        variables.activeCellKey.value.includes(`Time${region.title}`);
      return h(
        FocusableTableCell,
        {
          id: row.id,
          class: [
            !functions.isValidMfrRegionOption(row.id, region.id)
              ? ["bordered", "base-cost", "editable-table-cell"]
              : "",
          ],
          ...functions.editableTableCellProps(),
          onCellClick: () => {
            functions.onFocusReset();
          },
        },
        () => [
          h(
            NPopover,
            {
              show:
                !functions.isValidMfrRegionOption(row.id, region.id) ||
                (variables.numberFieldError.value &&
                  variables.activeCellId.value === row.id &&
                  isBaseCostRowKey),
              placement: "top-start",
              showArrow: false,
              class: "validation-tooltip",
              onClickoutside: (e: MouseEvent) => {
                if (variables.numberFieldError.value) {
                  variables.numberFieldError.value =
                    variables.isEditModeCell.value;
                }
                if (functions.isValidMfrRegionOption(row.id, region.id)) {
                  return;
                }
                functions.onClickOutsideBaseCostPopover?.(
                  e,
                  row,
                  region,
                  variables.disableTable
                );
              },
            },
            {
              trigger: () =>
                h(EditableTableCell, {
                  ref: !functions.isValidMfrRegionOption(row.id, region.id)
                    ? variables.costRef
                    : undefined,
                  value: cost && cost !== "0" ? `${cost}` : "",
                  withSavingLogic: false,
                  prefix: cost && cost !== "0" ? region.currencySign : "",
                  isString: true,
                  showEditButton: false,
                  showSaveButton: false,
                  triggerOnBlur: true,
                  showPlaceholder: !functions.isValidMfrRegionOption(
                    row.id,
                    region.id
                  ),
                  disabled:
                    functions.disableCellValue(row.id, rowKey) || !available,
                  isUpdateAfterSendingRequest: false,
                  onThenActionsTrigger:
                    onThenActionsTrigger.value &&
                    triggeredRegionId.value === region.id &&
                    triggeredRowId.value === row.id,
                  validateAndGetPayload: (inputValue: string) =>
                    functions.validateAndGetNumberPayload(inputValue, "Cost"),
                  updateFunction: (newCost: string) => {
                    displayDefaultMessage.value =
                      !functions.isValidMfrRegionOption(row.id, region.id);
                    onUpdateCellFunction(
                      functions.onUpdateCost?.(newCost, row, region),
                      () => {
                        if (newCost !== "") {
                          functions.onUpdateCostValue?.(newCost, row, region);
                        }
                      },
                      region.id,
                      row.id
                    );
                  },
                  updateMessage: `${region.title} ${
                    displayDefaultMessage.value ? "availability" : "cost"
                  } is successfully updated`,
                  onClick: () => {
                    functions.onSetActiveCell(row.id, rowKey);
                    functions.isValidMfrRegionOption(row.id, region.id);
                  },
                  onEditMode: (isEdit: boolean) => {
                    variables.isEditModeCell.value = isEdit;
                  },
                }),
              default: () => h("div", {}, getValidationMessage()),
            }
          ),
        ]
      );
    },
  });
  return { costColumn };
};
