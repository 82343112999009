import { ref, Ref } from "vue";

export default (tableRef: Ref<HTMLElement | undefined>) => {
  let resizeObserver;
  const tableHeaderHeight = ref(0);
  const setResizeObserver = () => {
    const tableHeader = tableRef.value?.getElementsByClassName(
      "n-data-table-base-table-header"
    )[0] as HTMLElement;
    resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          tableHeaderHeight.value = entry.contentBoxSize[0].blockSize;
        }
      }
    });
    if (tableHeader) {
      resizeObserver.observe(tableHeader);
    }
  };

  return {
    setResizeObserver,
    tableHeaderHeight,
  };
};
