import { h, Ref, ref, watch } from "vue";
import { NInput, SelectOption } from "naive-ui";
import { onFocusElement } from "@/composables/onFocusElement";

export default (
  selectInForm: boolean,
  filterable: boolean,
  addable: boolean,
  options: Ref<SelectOption[]>,
  selectorOptions: Ref,
  isEdit: Ref,
  newValueKey: string,
  inputRef: Ref,
  cellRef: Ref,
  emit: (
    event:
      | "addOption"
      | "deleteOption"
      | "changeOption"
      | "editMode"
      | "keydownTab"
      | "keydownEsc",
    ...args: any[]
  ) => void,
  onPressEnter: (value?: string) => void
) => {
  const inputValue = ref<string>();
  const optionsArray = ref(options.value);

  watch(
    () => options,
    () => {
      optionsArray.value = options.value;
    },
    { deep: true }
  );
  const renderHeader = () => () =>
    h(
      "div",
      {
        class: ["selector-header", selectInForm ? "select-in-form" : ""],
      },
      [
        h("button", {
          class: "close-button",
          onClick: () => {
            isEdit.value = false;
          },
        }),
        h(NInput, {
          class: ["selector-search", "input-borderless"],
          ref: inputRef,
          value: inputValue.value,
          placeholder: filterable
            ? addable
              ? "Search or Add"
              : "Search"
            : "Select",
          onClick: () => {
            onFocusElement(inputRef);
          },
          onInput: (value: string) => {
            selectorOptions.value = optionsArray.value.filter(
              (item: SelectOption) =>
                (item.label as string)
                  .toLowerCase()
                  .includes(value.toLowerCase())
            );
            if (addable) {
              const index = selectorOptions.value.findIndex(
                (item: { label: string; value: string }) => item.label === value
              );
              if (index !== -1 || !value) {
                return;
              }
              selectorOptions.value.push({
                label: value,
                value: `${newValueKey}${value}`,
                class: "new-value",
              });
            }
          },
          onUpdateValue: (value: string) => {
            inputValue.value = value;
          },
          onBlur: () => {
            inputValue.value = "";
          },
          onKeydown: (e: KeyboardEvent) => {
            if (e.code === "Space") {
              e.stopPropagation();
            } else if (e.code === "Escape") {
              isEdit.value = false;
              emit("keydownEsc");
              e.stopPropagation();
              e.preventDefault();
            } else if (e.code === "Tab") {
              isEdit.value = false;
              emit("keydownTab", { cellRef, shiftKey: e.shiftKey });
              e.preventDefault();
            } else if (e.code === "Enter") {
              onPressEnter(inputValue.value);
              e.preventDefault();
            }
          },
        }),
      ]
    );
  return { renderHeader };
};
