import {
  moreThanMessage,
  notANumberValueMessage,
  requiredMessage,
} from "@/composables/validationMessages";
import { FormItemRule } from "naive-ui";

const baseCostNumberFieldRules = (field: string): FormItemRule => {
  return {
    validator(_rule: FormItemRule, value: string): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        if (!value) {
          reject(Error(requiredMessage(field)));
        } else if (isNaN(+value)) {
          reject(Error(notANumberValueMessage(field)));
        } else if (+value <= 0) {
          reject(Error(moreThanMessage(field, 0)));
        } else {
          resolve();
        }
      });
    },
    trigger: ["input", "blur"],
  };
};

export default baseCostNumberFieldRules;
