import { Ref } from "vue";
import { SortKeyEnum } from "@/enums/costing-parameters/SortKeyEnum";
import { SpecialFieldsForSortingEnum } from "@/enums/costing-parameters/SpecialFieldsForSortingEnum";
import MaterialInterface from "@/types/admin-parameters/costing-parameters/MaterialInterface";
import SurfaceTreatmentInterface from "@/types/admin-parameters/costing-parameters/SurfaceTreatmentInterface";
import MfgProcessInterface from "@/types/admin-parameters/costing-parameters/MfgProcessInterface";
import { SalesRegionInterface } from "@/types/SalesRegionInterface";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";

export default (
  data: Ref<
    MaterialInterface[] | SurfaceTreatmentInterface[] | MfgProcessInterface[]
  >,
  mfrRegions: Ref<SalesRegionInterface[]>,
  onResetSorting: () => void
) => {
  const handleSorterChange = (event: {
    columnKey: string;
    order: SortKeyEnum | boolean;
  }) => {
    if (!event) {
      return;
    }
    if (!event.order) {
      onResetSorting();
    }
    data.value = data.value.sort(
      (a: Record<string, any>, b: Record<string, any>) => {
        if (typeof a[event.columnKey] === "string") {
          return sortAsString(
            a[event.columnKey],
            b[event.columnKey],
            event.order as SortKeyEnum
          );
        } else if (
          typeof a[event.columnKey] === "number" ||
          typeof a[event.columnKey] === "boolean"
        ) {
          return sortAsNumber(
            a[event.columnKey],
            b[event.columnKey],
            event.order as SortKeyEnum
          );
        } else {
          return sortBySpecialColumnKey(
            event.columnKey as SpecialFieldsForSortingEnum,
            a,
            b,
            event.order as SortKeyEnum
          );
        }
      }
    );
  };

  const sortBySpecialColumnKey = (
    columnKey: SpecialFieldsForSortingEnum,
    firstValue: Record<string, any>,
    secondValue: Record<string, any>,
    order: SortKeyEnum
  ) => {
    switch (columnKey) {
      case SpecialFieldsForSortingEnum.MATERIAL_GROUP: {
        return sortAsString(
          firstValue[columnKey].name,
          secondValue[columnKey].name,
          order
        );
      }
      case SpecialFieldsForSortingEnum.ALTERNATIVE_MATERIALS: {
        return sortAsString(
          firstValue[columnKey]?.[0]?.name || null,
          secondValue[columnKey]?.[0]?.name || null,
          order
        );
      }
      case SpecialFieldsForSortingEnum.SHAPES: {
        return sortAsString(
          firstValue[columnKey]?.[0] || null,
          secondValue[columnKey]?.[0] || null,
          order
        );
      }
      default: {
        return sortBySpecialBaseCostColumnKey(
          columnKey,
          firstValue.baseCosts,
          secondValue.baseCosts,
          order
        );
      }
    }
  };

  const getMfrRegionId = (
    columnKey: string,
    field: SpecialFieldsForSortingEnum
  ) => {
    const mfrRegionTitle = columnKey.split(field)?.[1];
    return (
      mfrRegions.value.find((item) => item.title === mfrRegionTitle)?.id || null
    );
  };
  const sortBySpecialBaseCostColumnKey = (
    columnKey: SpecialFieldsForSortingEnum,
    firstValue: Record<string, any>,
    secondValue: Record<string, any>,
    order: SortKeyEnum
  ) => {
    if (columnKey.includes(SpecialFieldsForSortingEnum.AVAILABILITY)) {
      return sortAsNumber(
        firstValue.find(
          (baseCostItem: BaseCostInterface) =>
            baseCostItem.mfrRegionId ===
            getMfrRegionId(columnKey, SpecialFieldsForSortingEnum.AVAILABILITY)
        )?.available || false,
        secondValue.find(
          (baseCostItem: BaseCostInterface) =>
            baseCostItem.mfrRegionId ===
            getMfrRegionId(columnKey, SpecialFieldsForSortingEnum.AVAILABILITY)
        )?.available || false,
        order
      );
    } else if (columnKey.includes(SpecialFieldsForSortingEnum.COST)) {
      return sortAsNumber(
        firstValue.find(
          (baseCostItem: BaseCostInterface) =>
            baseCostItem.mfrRegionId ===
            getMfrRegionId(columnKey, SpecialFieldsForSortingEnum.COST)
        )?.cost || false,
        secondValue.find(
          (baseCostItem: BaseCostInterface) =>
            baseCostItem.mfrRegionId ===
            getMfrRegionId(columnKey, SpecialFieldsForSortingEnum.COST)
        )?.cost || false,
        order
      );
    } else if (columnKey.includes(SpecialFieldsForSortingEnum.LEAD_TIME)) {
      return sortAsNumber(
        firstValue.find(
          (baseCostItem: BaseCostInterface) =>
            baseCostItem.mfrRegionId ===
            getMfrRegionId(columnKey, SpecialFieldsForSortingEnum.LEAD_TIME)
        )?.leadTime || false,
        secondValue.find(
          (baseCostItem: BaseCostInterface) =>
            baseCostItem.mfrRegionId ===
            getMfrRegionId(columnKey, SpecialFieldsForSortingEnum.LEAD_TIME)
        )?.leadTime || false,
        order
      );
    }
    return 0;
  };

  const sortAsString = (
    firstValue: string,
    secondValue: string,
    order: SortKeyEnum
  ) => {
    if (firstValue === null && secondValue === null) {
      return 0;
    } else if (firstValue === null) {
      return 1;
    } else if (secondValue === null) {
      return -1;
    }

    return (
      firstValue.localeCompare(secondValue) *
      (order === SortKeyEnum.DESCEND ? 1 : -1)
    );
  };

  const sortAsNumber = (
    firstValue: number,
    secondValue: number,
    order: SortKeyEnum
  ) => {
    if (firstValue === null && secondValue === null) {
      return 0;
    } else if (firstValue === null) {
      return 1;
    } else if (secondValue === null) {
      return -1;
    }

    return (
      (firstValue - secondValue) * (order === SortKeyEnum.DESCEND ? 1 : -1)
    );
  };

  return {
    handleSorterChange,
  };
};
