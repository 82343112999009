import { createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "description"
}
const _hoisted_2 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "notice-message-modal main-modal",
    preset: "dialog",
    "show-icon": false,
    title: $props.title,
    closable: $props.showCloseButton,
    onMaskClick: _cache[3] || (_cache[3] = ($event: any) => ($props.closeOnBackdropClick ? _ctx.$emit('close') : () => {}))
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Loader"], { loading: $props.loading }, null, 8, ["loading"]),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        ($props.text)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($props.text), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        ($props.infoModal)
          ? (_openBlock(), _createBlock($setup["NButton"], {
              key: 0,
              type: "info",
              round: "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.actionText), 1)
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode($setup["NButton"], {
                class: "small-button n-button-delete",
                type: $props.withErrorTypeButton ? 'error' : 'info',
                round: "",
                disabled: $props.disableAction,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('action')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.actionText), 1)
                ]),
                _: 1
              }, 8, ["type", "disabled"]),
              _createVNode($setup["NButton"], {
                class: "medium-button n-button-cancel",
                type: "tertiary",
                round: "",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.cancelText), 1)
                ]),
                _: 1
              })
            ], 64))
      ])
    ]),
    _: 3
  }, 8, ["title", "closable"]))
}