import { ref, Ref } from "vue";
import { AxiosResponse } from "axios";
import useBaseCostByMfrRegion from "@/composables/costing-parameters/useBaseCostByMfrRegion";
import useFocusableTableCellMethods from "@/composables/costing-parameters/useFocusableTableCellMethods";
import useCostingParameterTableMethods from "@/composables/costing-parameters/useCostingParameterTableMethods";
import useBaseCostAvailabilityColumn from "@/composables/costing-parameters/useBaseCostAvailabilityColumn";
import useBaseCostCostingColumn from "@/composables/costing-parameters/useBaseCostCostingColumn";
import useBaseCostLeadTimeColumn from "@/composables/costing-parameters/useBaseCostLeadTimeColumn";
import useBaseCostMoqColumn from "@/composables/costing-parameters/useBaseCostMoqColumn";
import useUpdateCellFunction from "@/composables/costing-parameters/useUpdateCellFunction";
import useBaseCostCTQLeadTimeColumn from "@/composables/costing-parameters/useBaseCostCTQLeadTimeColumn";
import useBaseCostMinCostColumn from "@/composables/costing-parameters/useBaseCostMinCostColumn";
import { SalesRegionInterface } from "@/types/SalesRegionInterface";
import AvailabilityInterface from "@/types/admin-parameters/costing-parameters/AvailabilityInterface";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";
import { TableColumnInterface } from "@/types/costing-parameters/TableColumnInterface";
import CostingParameterService from "@/services/CostingParameterService";

export default (
  columns: Ref<TableColumnInterface[]>,
  tableRef: Ref<HTMLElement | undefined>,
  loading: Ref<boolean>,
  titles: {
    costTitle: string;
    leadTimeTitle: string;
    ctqLeadTimeTitle?: string;
  },
  baseCostByMfrRegionPayload: {
    data: Ref<AvailabilityInterface[]>;
    keyField: string;
    addRequest: (payload: BaseCostInterface[]) => Promise<AxiosResponse>;
    changeRequest: (payload: BaseCostInterface) => Promise<AxiosResponse>;
    onClickCostCell: () => void;
    onClickLeadTimeCell: () => void;
    onClickMinCostCell?: () => void;
    onClickCTQDimLeadTimeCell?: () => void;
    onClickMOQCell?: () => void;
    startLoader: () => void;
    finishLoader: () => void;
  },
  focusableCellMethods: {
    editableTableCellProps: () => {
      tableRef: Ref<HTMLElement | undefined>;
      editMode: boolean;
      focusResetCounter: number;
      childClass: string;
    };
    onFocusReset: () => void;
    selectableTableCellProps: () => {
      tableRef: Ref<HTMLElement | undefined>;
      editMode: boolean;
      focusResetCounter: number;
      blurRowId: string;
      tabRowId: string;
      tabRowKey: string;
      shiftKey: boolean;
      childClass: string;
    };
    onKeydownEsc: (id: string) => void;
    onKeydownTab: (
      e: { shiftKey: boolean },
      id: string,
      rowKey: string
    ) => void;
  },
  showColumns = {
    showLeadTimeColumn: true,
    showCTQLeadTimeColumn: false,
    showMOQColumn: false,
    showMinCostColumn: false,
  }
) => {
  const mfrRegions = ref<SalesRegionInterface[]>([]);
  const getMfrRegions = (): Promise<void> | undefined => {
    if (
      columns.value.findIndex((item) =>
        `${item.title}`.includes("Manufacturing Region")
      ) !== -1
    ) {
      return;
    }
    return CostingParameterService.getMfrRegions().then((res) => {
      mfrRegions.value = res.data.data.sort((a: SalesRegionInterface) => {
        return a.title === "China" ? -1 : 1;
      });
      setMfrRegionsColumns();
    });
  };
  const { isEditModeCell } = useFocusableTableCellMethods(tableRef);

  const {
    activeCellId,
    activeCellKey,
    disableTable,
    disableCellValue,
    onSetActiveCell,
    numberFieldError,
    validationFieldName,
    validateAndGetNumberPayload,
    validateAndGetNotNegativeNumberPayload,
  } = useCostingParameterTableMethods(isEditModeCell, loading);

  //base cost
  const costRef = ref<HTMLElement | undefined>();
  const leadTimeRef = ref<HTMLElement | undefined>();
  const ctqDimLeadTimeRef = ref<HTMLElement | undefined>();
  const moqRef = ref<HTMLElement | undefined>();
  const minCostRef = ref<HTMLElement | undefined>();

  const {
    baseCostValidationError,
    isValidMfrRegionOption,
    getMfrRegionOptions,
    onUpdateAvailability,
    onUpdateAvailabilityValue,
    onUpdateCost,
    onUpdateCostValue,
    onUpdateLeadTime,
    onUpdateLeadTimeValue,
    onUpdateCTQDimLeadTime,
    onUpdateCTQDimLeadTimeValue,
    onUpdateMoq,
    onUpdateMoqValue,
    onUpdateMinCost,
    onUpdateMinCostValue,
    onClickOutsideBaseCostPopover,
  } = useBaseCostByMfrRegion(
    baseCostByMfrRegionPayload.data,
    baseCostByMfrRegionPayload.keyField,
    showColumns,
    baseCostByMfrRegionPayload.addRequest,
    baseCostByMfrRegionPayload.changeRequest,
    baseCostByMfrRegionPayload.onClickCostCell,
    baseCostByMfrRegionPayload.onClickLeadTimeCell,
    baseCostByMfrRegionPayload.onClickMinCostCell,
    baseCostByMfrRegionPayload.onClickCTQDimLeadTimeCell,
    baseCostByMfrRegionPayload.onClickMOQCell
  );

  const baseCostFunctionsPayload = {
    getMfrRegionOptions,
    disableCellValue,
    onSetActiveCell,
    isValidMfrRegionOption,
    editableTableCellProps: focusableCellMethods.editableTableCellProps,
    onFocusReset: focusableCellMethods.onFocusReset,
    onClickOutsideBaseCostPopover,
    validateAndGetNumberPayload,
    validateAndGetNotNegativeNumberPayload,
    onUpdateCost,
    onUpdateCostValue,
    onUpdateLeadTime,
    onUpdateLeadTimeValue,
    onUpdateCTQDimLeadTime,
    onUpdateCTQDimLeadTimeValue,
    onUpdateMoq,
    onUpdateMoqValue,
    onUpdateMinCost,
    onUpdateMinCostValue,
    selectableTableCellProps: focusableCellMethods.selectableTableCellProps,
    onKeydownEsc: focusableCellMethods.onKeydownEsc,
    onKeydownTab: focusableCellMethods.onKeydownTab,
    onUpdateAvailability,
    onUpdateAvailabilityValue,
    startLoader: baseCostByMfrRegionPayload.startLoader,
    finishLoader: baseCostByMfrRegionPayload.finishLoader,
  };
  const { availabilityColumn } = useBaseCostAvailabilityColumn(
    { isEditModeCell },
    baseCostFunctionsPayload
  );

  const { costColumn } = useBaseCostCostingColumn(
    {
      costRef,
      costTitle: titles.costTitle,
      activeCellKey,
      activeCellId,
      numberFieldError,
      validationFieldName,
      isEditModeCell,
      disableTable,
      showLeadTimeColumn: showColumns.showLeadTimeColumn,
      showCTQLeadTimeColumn: showColumns.showCTQLeadTimeColumn,
      showMOQColumn: showColumns.showMOQColumn,
      showMinCostColumn: showColumns.showMinCostColumn,
    },
    baseCostFunctionsPayload
  );

  const { leadTimeColumn } = useBaseCostLeadTimeColumn(
    {
      leadTimeRef,
      isEditModeCell,
      leadTimeTitle: titles.leadTimeTitle,
      showCTQLeadTimeColumn: showColumns.showCTQLeadTimeColumn,
    },
    baseCostFunctionsPayload
  );
  const { ctqDimLeadTimeColumn } = useBaseCostCTQLeadTimeColumn(
    {
      ctqDimLeadTimeRef,
      isEditModeCell,
      ctqLeadTimeTitle: titles.ctqLeadTimeTitle || "",
    },
    baseCostFunctionsPayload
  );

  const { moqColumn } = useBaseCostMoqColumn(
    { moqRef, isEditModeCell },
    baseCostFunctionsPayload
  );

  const { minCostColumn } = useBaseCostMinCostColumn(
    { minCostRef, isEditModeCell },
    baseCostFunctionsPayload
  );

  const setMfrRegionsColumns = () => {
    columns.value.push(
      ...mfrRegions.value.map((region) => ({
        title: `${region.title} Manufacturing Region`,
        key: `${region.title} Manufacturing Region`,
        children: [
          availabilityColumn(region),
          ...(showColumns.showMOQColumn ? [moqColumn(region)] : []),
          costColumn(region),
          ...(showColumns.showMinCostColumn ? [minCostColumn(region)] : []),
          ...(showColumns.showLeadTimeColumn ? [leadTimeColumn(region)] : []),
          ...(showColumns.showCTQLeadTimeColumn
            ? [ctqDimLeadTimeColumn(region)]
            : []),
        ],
      }))
    );
  };
  return {
    mfrRegions,
    getMfrRegions,
    setMfrRegionsColumns,
    baseCostValidationError,
    costRef,
    leadTimeRef,
    ctqDimLeadTimeRef,
    moqRef,
    minCostRef,
    activeCellId,
    activeCellKey,
    disableTable,
    disableCellValue,
    onSetActiveCell,
    numberFieldError,
    isEditModeCell,
  };
};
