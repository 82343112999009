import { h, Ref, ref } from "vue";
import FocusableTableCell from "@/components/FocusableTableCell.vue";
import EditableTableCell from "@/components/EditableTableCell.vue";
import useUpdateCellFunction from "@/composables/costing-parameters/useUpdateCellFunction";
import { SalesRegionInterface } from "@/types/SalesRegionInterface";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";
import { BaseCostPayloadInterface } from "@/types/costing-parameters/BaseCostPayloadInterface";

export default (
  variables: {
    ctqDimLeadTimeRef: Ref<HTMLElement | undefined>;
    isEditModeCell: Ref<boolean>;
    ctqLeadTimeTitle: string;
  },
  functions: BaseCostPayloadInterface
) => {
  const displayDefaultMessage = ref(false);

  const {
    onThenActionsTrigger,
    triggeredRegionId,
    triggeredRowId,
    onUpdateCellFunction,
  } = useUpdateCellFunction(
    variables.isEditModeCell,
    functions.startLoader,
    functions.finishLoader
  );

  const ctqDimLeadTimeColumn = (region: SalesRegionInterface) => ({
    title: variables.ctqLeadTimeTitle,
    key: `ctqLeadTime${region.title}`,
    width: 120,
    render: (row: { id: string; baseCosts: BaseCostInterface[] }) => {
      const rowKey = `ctqLeadTime${region.title}`;
      const { ctqDimLeadTime, available } = functions.getMfrRegionOptions(
        row.id,
        region.id
      );
      return h(
        FocusableTableCell,
        {
          id: row.id,
          class: [
            !functions.isValidMfrRegionOption(row.id, region.id)
              ? ["bordered", "base-cost", "editable-table-cell"]
              : "",
          ],
          ...functions.editableTableCellProps(),
          onCellClick: () => {
            functions.onFocusReset();
          },
        },
        () => [
          h(EditableTableCell, {
            ref: !functions.isValidMfrRegionOption(row.id, region.id)
              ? variables.ctqDimLeadTimeRef
              : undefined,
            value:
              ctqDimLeadTime && ctqDimLeadTime !== "0"
                ? `${ctqDimLeadTime}`
                : "",
            withSavingLogic: false,
            showEditButton: false,
            showSaveButton: false,
            triggerOnBlur: true,
            showPlaceholder: !functions.isValidMfrRegionOption(
              row.id,
              region.id
            ),
            disabled: functions.disableCellValue(row.id, rowKey) || !available,
            isUpdateAfterSendingRequest: false,
            onThenActionsTrigger:
              onThenActionsTrigger.value &&
              triggeredRegionId.value === region.id &&
              triggeredRowId.value === row.id,
            validateAndGetPayload: (inputValue: string) =>
              functions.validateAndGetNumberPayload(inputValue, "CTQ Dim. LT"),
            updateFunction: (newLeadTime: string) => {
              displayDefaultMessage.value = !functions.isValidMfrRegionOption(
                row.id,
                region.id
              );
              onUpdateCellFunction(
                functions.onUpdateCTQDimLeadTime?.(newLeadTime, row, region),
                () => {
                  if (newLeadTime !== "") {
                    functions.onUpdateCTQDimLeadTimeValue?.(
                      newLeadTime,
                      row,
                      region
                    );
                  }
                },
                region.id,
                row.id
              );
            },
            updateMessage: `${region.title} ${
              displayDefaultMessage.value ? "availability" : "CTQ Dim. LT"
            } is successfully updated`,
            onClick: () => {
              functions.onSetActiveCell(row.id, rowKey);
              functions.isValidMfrRegionOption(row.id, region.id);
            },
            onEditMode: (isEdit: boolean) => {
              variables.isEditModeCell.value = isEdit;
            },
          }),
        ]
      );
    },
  });
  return { ctqDimLeadTimeColumn };
};
