import { ref } from "vue";
import { AvailabilityValueEnum } from "@/enums/AvailabilityValueEnum";
import { MfgProcessAccuracyLevelValueEnum } from "@/enums/costing-parameters/MfgProcessAccuracyLevelValueEnum";
import { MfgProcessAccuracyLevelEnum } from "@/enums/costing-parameters/MfgProcessAccuracyLevelEnum";
import { OptionsInterface } from "@/types/costing-parameters/OptionsInterface";

export default () => {
  const rowOptions = ref([
    {
      label: "Delete",
      key: "delete",
    },
  ]);

  const availabilityOptions = ref<OptionsInterface[]>([
    {
      label: AvailabilityValueEnum.Yes,
      value: AvailabilityValueEnum.Yes,
    },
    {
      label: AvailabilityValueEnum.No,
      value: AvailabilityValueEnum.No,
    },
  ]);

  const accuracyLevelOptions = ref<OptionsInterface[]>([
    {
      label: MfgProcessAccuracyLevelEnum.LOW,
      value: MfgProcessAccuracyLevelValueEnum.LOW,
    },
    {
      label: MfgProcessAccuracyLevelEnum.MIDDLE,
      value: MfgProcessAccuracyLevelValueEnum.MIDDLE,
    },
    {
      label: MfgProcessAccuracyLevelEnum.HIGH,
      value: MfgProcessAccuracyLevelValueEnum.HIGH,
    },
  ]);

  return {
    rowOptions,
    availabilityOptions,
    accuracyLevelOptions,
  };
};
